export const SIDEBAR_WIDTH = 240;
export const MINISIDEBAR_WIDTH = 100;
export const TOPCONTENT_HEIGHT = 200;

export const ROUTES = {
  ROOT: "/",
  HOME: "/home/:types",
  HOME_URL: "/home/random",
  SIGNIN: "/signin",
  TRENDING: "/home/trending",
  TERMS: "/home/terms",
  SUBSCRIPTIONS: "/home/sub",
  NEWEST: "/home/newest",
};
