import React from "react";
import { BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider, positions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Newest from "./pages/Newest";
import Trending from "./pages/Trending";
import Subscriptions from "./pages/Subscriptions";
import Video from "./pages/Video";
import SignIn from "./pages/SignIn";
import Search from "./pages/search";
import Channel from "./pages/channel";
import Dashboard from "./pages/Dashboard";
import SignUp from "./pages/SignUp";

import Terms from "./pages/Terms";
import CommunityGuidelines from "./pages/CommunityGuidlines";
import PrivacyPolicy from "./pages/PrivacyPolicy";


import { darkTheme, lightTheme } from "./utils/Theme";
import "./assets/css/styles.css";
import "./css/app.css";

const stripePromise = loadStripe('your-publishable-key-here');

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const AppContent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const location = useLocation();
  const { darkMode } = useSelector((state) => state.theme);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isVideoPage = location.pathname.startsWith('/video');

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className={`App ${darkMode ? 'dark-theme' : ''}`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="content-wrapper">
          {(!isVideoPage || isSidebarOpen) && (
            <Sidebar 
              isOpen={isSidebarOpen} 
              onClose={() => setIsSidebarOpen(false)}
            />
          )}
          <div className={`main-content ${isSidebarOpen && !isVideoPage ? 'sidebar-open' : ''}`}>
            <Routes>
              <Route path="/">
                <Route index element={<Home type="random" />} />
                <Route path="trending" element={<Trending />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="newest" element={<Newest />} />
                <Route path="signin" element={<SignIn />} />
                <Route path="terms" element={<Terms />} />
                <Route path="community-guidelines" element={<CommunityGuidelines />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="search" element={<Search />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="channel">
                  <Route
                    path=":id"
                    element={
                      <Elements stripe={stripePromise}>
                        <Channel />
                      </Elements>
                    }
                  />
                </Route>
                <Route path="video">
                  <Route path=":id" element={<Elements stripe={stripePromise}>
                        <Video />
                      </Elements>} />
                </Route>
                <Route path="dashboard">
                  <Route path=":id" element={<Dashboard />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const App = () => (
  <Provider template={AlertTemplate} {...options}>
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  </Provider>
);

export default App;
