import React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserPrefferedDarkTheme,
  setUserPrefferedLightTheme,
  setSystemPrefferedDarkTheme,
  setSystemPrefferedLightTheme,
} from "../redux/themeSlice";
import { useTheme } from "@mui/material/styles";
import "../css/terms.css";
import TermsOfService from "../components/Legal/Terms";

const Container = styled.div`

`;

function Terms() {
  return (
    <div className="mainContent">
      <TermsOfService/>
    </div>
  );
}

export default Terms;
