import { useState, useEffect } from 'react';
import { Menu, IconButton } from "@mui/material";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { styled } from "@mui/material/styles";
import { fetchSuccess, like, dislike } from "../redux/videoSlice";

import { useSelector } from "react-redux";


import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

// const customEmojis = [
//   {
//     id: 'custom_emoji_1',
//     name: 'Custom Emoji 1',
//     short_names: ['custom1'],
//     custom: true,
//     imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s'
//   },
//   {
//     id: 'custom_emoji_2',
//     name: 'Custom Emoji 2',
//     short_names: ['custom2'],
//     custom: true,
//     imageUrl: 'https://imageio.forbes.com/specials-images/imageserve/602c09c9135a060af5e1a8f4/Face-with-Spiral-Eyes---a-new-Apple-emoji-/960x0.png?format=png&width=960'
//   }
//   // Add more custom emojis as needed
// ];


const custom = [
  {
    id: 'custom',
    name: 'Custom',
    emojis: [
      {
        id: 'octocat',
        name: 'Octocat',
        keywords: ['github'],
        skins: [{  src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      },
      {
        id: 'octocat222',
        name: 'Octocat2222',
        keywords: ['github222'],
        skins: [{ src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      },
      {
        id: 'octocat333',
        name: 'Octocat3333',
        keywords: ['github3333'],
        skins: [{ src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      }
    ],
  }
]


const StyledEmojiMenu =  styled(Menu)`

  .MuiPaper-root {
    background: none;
  }

  .MuiList-root {
    padding: 0;
  }
`;


const EmojiMenu = ({addEmojiToText}) => {
  const { currentVideo } = useSelector((state) => state.video);
  console.log('data of emojis************', data)
  const [customEmojis, setCustomEmojis] = useState([]);


  useEffect(() => {
    // You can fetch or generate the emojis here dynamically
    const fetchedEmojis = [
      {
        id: 'octocat',
        name: 'Octocat',
        skins: [{ native: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      },
      {
        id: 'octocat2',
        name: 'Octocat2',
        skins: [{ native: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      },
      {
        id: 'octocat3',
        name: 'Octocat3',
        skins: [{ src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPg0dDKithB0pyglCNKnKBryx8l2nuA8LDdA&s' }],
      }
    ];

    let allCustomEmojis = [
      {
        id: 'custom',
        name: 'Custom',
        emojis: currentVideo?.channelEmojis ?? []
      }
    ]

    setCustomEmojis(allCustomEmojis);
  }, []); 
  console.log('currentVideo-------------', currentVideo)
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addEmoji = (emoji) => {
    console.log('emoji.native', emoji)

    const emojiToShow = emoji?.native ? emoji?.native : emoji?.id;

    addEmojiToText((prev) => `${prev}${emojiToShow}`);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <EmojiEmotionsIcon />
      </IconButton>
      <StyledEmojiMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="emoji-menu"
      >
        <Picker data={data} custom={customEmojis} onEmojiSelect={addEmoji} />
      </StyledEmojiMenu>
    </>
  );
};

export default EmojiMenu;
