// Channel.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../utils/client";
import { useAlert } from 'react-alert';
import { Box, Typography, Button, SvgIcon, Divider, Alert } from "@mui/material";
import { ContentPasteSearchOutlined, Person as PersonIcon } from "@mui/icons-material";
import { subscription } from "../../redux/userSlice";
import ChannelCards from "./ChannelCards";
import ChannelAbout from "./ChannelAbout";
import MembershipModal from "./MembershipModal"; // Import the MembershipModal component
import {
  StyledChannelBanner,
  StyledTabButton,
  StyledAvatar,
  StyledChannelIntroBox,
  StyledChannelBannerWrapper,
} from "./styles";
import User from "lucide-react"
import Avatar from "../../assets/images/default-avatar.jpg";
import "../../css/channel.css"
import { useTheme } from "styled-components";
// import {Avatar} from "@mui/material";

const Channel = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [tabValue, setTabValue] = useState(0);
  const [channel, setChannel] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const [videos, setVideos] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [totalWatchedHours, setTotalWatchedHours] = useState(0);
  const [isMemberShipModalOpen, setMemberShipModalOpen] = useState(false); // State to manage modal visibility

  const theme = useTheme();
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const stripeSessionId = queryParams.get('session_id');
  const showMemberShipModal = queryParams.get('show_member_ship_modal');

  const handleTabChange = (event) => {
    setTabValue(+event.target.attributes.dataindex.value);
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const updateUserPayment = async (stripeSessionId, id) => {
    try {
      await axiosClient.get(`/api/users/join-success/${id}/${stripeSessionId}`);
      alert.show('Thank you for the payment!', {
        type: 'success'
      });
    } catch ({message}) {
      console.log(message);
    }
  }

  useEffect(() => {
    if (stripeSessionId) {
      updateUserPayment(stripeSessionId, id);
      queryParams.delete('session_id');
      navigate({
        pathname: location.pathname,
        search: queryParams.toString()
      });
    }
    if (showMemberShipModal) {
      setMemberShipModalOpen(true);
      queryParams.delete('show_member_ship_modal');
      navigate({
        pathname: location.pathname,
        search: queryParams.toString()
      });
    }
  }, [stripeSessionId, id, updateUserPayment, setMemberShipModalOpen]);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const channelRes = await axiosClient.get(`/api/users/find/${id}`);
        setChannel(channelRes?.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData(id);
  }, [id]);

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoadingVideos(true);
      try {
        const videoRes = await axiosClient.get(`/api/videos/channel/${id}`);
        setVideos(videoRes?.data);
        const totalViews = videoRes.data.reduce(
          (sum, video) => sum + video.views,
          0
        );

        const totalWatchTimeInSeconds = videoRes.data.reduce(
          (sum, video) => sum + (video.watchTime ?? 0),
          0
        );

        const totalWatchTimeInHours = Math.floor(totalWatchTimeInSeconds / 3600);
        console.log('totalWatchTimeInHours', totalWatchTimeInHours)
        setTotalWatchedHours(totalWatchTimeInHours)
        setTotalViews(totalViews);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoadingVideos(false);
      }
    };
    fetchVideos();
  }, [id]);

  const handleSub = async () => {
    currentUser.subscribedUsers.includes(channel?._id)
      ? await axiosClient.put(`/api/users/unsub/${channel?._id}`)
      : await axiosClient.put(`/api/users/sub/${channel?._id}`);
    dispatch(subscription(channel?._id));
  };

  const handleModalOpen = () => {
    setMemberShipModalOpen(true);
    setShowValue(true)
  };

  const handleModalClose = () => {
    setMemberShipModalOpen(false);
    setShowValue(false)
  };

  const setActive = (value, index) =>
    value === index ? "contained" : "outlined";

  return (
    <div className="channel-page">
    {channel?.channelInfo?.channelBanner && (
        <div 
          className="channel-banner"
          style={{ backgroundImage: `url(${channel.channelInfo.channelBanner})` }}
        ></div>
      )}
    <div className="channel-page-info">
      {/* <img className="channel-page-avatar" src={channel?.img} alt={channel?.username} /> */}
            {channel?.img ? (
              <img 
                className="channel-page-avatar" 
                src={channel.img} 
              />
            ) : (
              <img src={Avatar} className="channel-page-avatar" />
            )}      
      <div className="channel-page-details">
        <h1 className="channel-page-name">{channel?.username}</h1>
        <p className="channel-page-stats">{channel?.subscribers} subscribers</p>
        <p className="channel-page-description">{channel?.channelInfo?.description}</p>
      </div>
      {currentUser && (
        <button className="channel-subscribe-button" onClick={handleSub}>
          {currentUser?.subscribedUsers?.includes(channel?._id)
            ? "SUBSCRIBED"
            : "SUBSCRIBE"}
        </button>
      )}
    </div>
    <div className="channel-tabs">
      <div 
        className={`channel-tab ${tabValue === 0 ? 'active' : ''}`} 
        onClick={() => setTabValue(0)}
      >
        Videos
      </div>
      <div 
        className={`channel-tab ${tabValue === 1 ? 'active' : ''}`} 
        onClick={() => setTabValue(1)}
      >
        About
      </div>
      <div 
        className={`channel-tab ${tabValue === 2 ? 'active' : ''}`} 
        onClick={handleModalOpen}
      >
        Join
      </div>
    </div>
    {tabValue === 0 && (
      <div className="channel-content">
        {channel?._id && (
          <ChannelCards isLoadingVideos={isLoadingVideos} videos={videos} toShowValue={showValue}/>
        )}
      </div>
    )}
    {tabValue === 1 && (
      <div className="channel-content">
        <ChannelAbout
          channel={channel}
          totalVideos={videos.length}
          totalViews={totalViews}
        />
      </div>
    )}
    <MembershipModal
      open={isMemberShipModalOpen}
      handleClose={handleModalClose}
      channel={channel}
      page={'channel'}
      pageId={channel?._id}
    />
  </div>
  );
};

export default Channel;
