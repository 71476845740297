import { Card, Skeleton, CardHeader } from '@mui/material';

const CardSkeleton = () => (
  <Card sx={{ mx: 2, my: 1, mr: '20px' }}>
    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
    <CardHeader
      avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
      title={<Skeleton animation="wave" height={20} width="90%" style={{ marginBottom: 6 }} /> }
      subheader={<Skeleton animation="wave" height={20} width="60%" />}
    />
  </Card>
);

export default CardSkeleton;
