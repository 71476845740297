import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "timeago.js";
import { ThumbsUp, ThumbsDown, Share, Save, Flag, CircleDollarSign } from 'lucide-react';
import { Paper, Typography, Button, Avatar, Box } from "@mui/material";

import "../css/video.css";
import Comments from "../components/Comments";
import RecommendedVideos from "../components/Recommendation";
import { useLocation, Link } from "react-router-dom";
import { useTheme } from "styled-components";


import { fetchSuccess, like, dislike } from "../redux/videoSlice";
import axiosClient from "../utils/client";
import { subscription } from "../redux/userSlice";
import Recommendation from "../components/Recommendation";
import Modal from "../components/Modal";
import StripeModal from "../components/StripeModal";
import ReportModal from "../components/ReportModal";

import {
  Paid as PaidIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  ArrowBack as ArrowBackIcon,
  OutlinedFlag as OutlinedFlagIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  ThumbDownOffAltOutlined as ThumbDownOffAltOutlinedIcon,
  ReplyOutlined as ReplyOutlinedIcon,
} from "@mui/icons-material";



import {
  Container,
  Content,
  SideBar,
  SideBarInner,
  Title,
  Details,
  Info,
  Buttons,
  Channel,
  ChannelInfo,
  Image,
  ChannelDetail,
  ChannelName,
  ChannelCounter,
  CommentsContainer,
  CommentsBackdrop,
  VideoWrapper,
  VideoFrame,
} from "./videoStyles";

const Video = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { currentVideo } = useSelector((state) => state.video);
  const timeWatched = useRef(0);
  const interval = useRef(null);
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to track the description expansion
  const [expandMobileComments, setExpandMobileComments] = useState(false); // State to track the description expansion
  const dispatch = useDispatch();
  //getting the path which is the video ID
  const path = useLocation().pathname.split("/")[2];
  //getting the url
  const url = window.location.href;
  const [channel, setChannel] = useState({});

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const videoRes = await axiosClient.get(`/api/videos/find/${path}`);
        const channelRes = await axiosClient.get(
          `/api/users/find/${videoRes.data.userId}`
        );
        setChannel(channelRes.data);
        console.log(videoRes.data);
        //SET THE CURRENT VIDEO BACK TO REDUX STATE
        dispatch(fetchSuccess(videoRes.data));
      } catch (err) {}
    };
    fetchData();
  }, [path, dispatch]);

  const handleLike = async () => {
    await axiosClient.put(`/api/users/like/${currentVideo._id}`);
    dispatch(like(currentUser._id));
  };
  const handleDislike = async () => {
    await axiosClient.put(`/api/users/dislike/${currentVideo._id}`);
    dispatch(dislike(currentUser._id));
  };
  const handleSub = async () => {
    currentUser?.subscribedUsers.includes(channel._id)
      ? await axiosClient.put(`/api/users/unsub/${channel._id}`)
      : await axiosClient.put(`/api/users/sub/${channel._id}`);
    dispatch(subscription(channel._id));
  };

  useEffect(() => {
    // If the video is not available then do nothing
    if (!videoRef.current) return;
    // If the interval is already running then do nothing, if timeWatched is not 0 the interval has already run and completed (added 1 view to the viewcount)
    if (interval.current || timeWatched.current !== 0) return;
    interval.current = setInterval(async () => {
      if (!videoRef.current) return;
      // If the video is paused then do nothing
      if (videoRef.current.paused) return;
      // Add 1 to the time watched every second
      timeWatched.current += 1;
      // If the video duration is less than 10 seconds and the user has watched the entire video, send a request to the server to add a view
     // console.log(timeWatched.current);
      //console.log(videoRef.current.duration);
      if (
        videoRef.current.duration < 10 &&
        timeWatched.current >= Math.floor(videoRef.current.duration)
      ) {
        //console.log(timeWatched.current);
        // Stop the interval
        clearInterval(interval.current);
        //set the inteerval back to null
        interval.current = null;
        try {
          await axiosClient.put(`/api/videos/view/${currentVideo._id}`);
        } catch (err) {
          console.log(err.reponse.data);
        }
      }
      // If the time watched is at least 10 seconds or the entire video duration, send a request to the server to add a view
      if (timeWatched.current >= Math.min(videoRef.current.duration, 10)) {
        // Stop the interval
        clearInterval(interval.current);
        //set the inteerval back to null
        interval.current = null;
        try {
          await axiosClient.put(`/api/videos/view/${currentVideo._id}`);
        } catch (err) {
          console.log(err.reponse.data);
        }
      }
      // Run this code every 1 second
    }, 1000);
    //clear the interval when the user leaves the
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        //set the interval back to null
        interval.current = null;
      }
    };
  }, [videoRef.current]);

  /*https://video-react.js.org/components/player/ */
  //add ts files for video learn ffmpeg
  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const maxDescriptionLength = 100; // Maximum number of characters to display in the description

  const handleMobileComments = () => {
    setExpandMobileComments((prev) => !prev);
  };

  const theme = useTheme();

  return (
    <div className="video-page">
      {currentVideo && (
        <div className="video-content">
          <div className="video-player-container">
            <video 
              className="video-player"
              controls 
              ref={videoRef}
              src={currentVideo.videoUrl}
            />
          </div>
          <h1 className="video-page-title">{currentVideo.title}</h1>
          <div className="video-page-info">
            <div className="channel-info">
              <Link to={`/channel/${channel._id}`} className="channel-avatar-link">
                {/* <img src={channel.img} alt={channel.name} className="video-channel-avatar " /> */}
                  {channel.img ? (
                      <img
                        src={channel.img}
                        className="video-channel-avatar"
                      />
                    ) : (
                      <Avatar  className="video-channel-avatar "/>
                    )}
              </Link>
              <div className="channel-details">
                <Link to={`/channel/${channel._id}`} className="channel-name">{channel.name}</Link>
                <span className="subscriber-count">{channel.subscribers} subscribers</span>
              </div>
              <button className="subscribe-button" onClick={handleSub}>
                {currentUser?.subscribedUsers?.includes(channel._id) ? "Subscribed" : "Subscribe"}
              </button>
            </div>
            <div className="video-actions">
              <div className="like-dislike-group">
                <button className="action-button like-button" onClick={handleLike}>
                  {currentVideo.likes?.includes(currentUser?._id) ? <ThumbsUp /> : <ThumbsUp />}
                  {currentVideo.likes?.length}
                </button>
                <button className="action-button dislike-button" onClick={handleDislike}>
                  {currentVideo.dislikes?.includes(currentUser?._id) ? <ThumbsDown /> : <ThumbsDown />}
                  {currentVideo.dislikes?.length}
                </button>
              </div>
              <button className="action-button share-button" onClick={() => setOpen(true)}>
                <Share /> Share
              </button>
              <button className="action-button tip-button" onClick={() => setOpenStripeModal(true)}>
                <CircleDollarSign style={{ color: "#70E000"}}/> Tip
              </button>
              <button className="action-button report-button" onClick={() => setOpenReportModal(true)}>
                <Flag /> Report
              </button>
            </div>
          </div>
          <div className="video-description">
            <div className="video-stats">
              <span className="view-count">{currentVideo.views} views</span>
              <span className="post-date">{format(currentVideo.createdAt)}</span>
            </div>
            <div className={`description-text ${expanded ? 'expanded' : ''}`}>
              <p>
                    {expanded
                        ? currentVideo?.desc
                        : `${currentVideo?.desc.slice(0, maxDescriptionLength)}`}
                </p>
              {currentVideo?.desc.length > maxDescriptionLength && (
                <button className="show-more-btn" onClick={toggleDescription}>
                  {expanded ? 'Show less' : 'Show more...'}
                </button>
              )}
            </div>
      
          </div>
          <Button
              variant="contained"
              size="large"
              onClick={handleMobileComments}
              sx={{ width: "100%", display: { sm: "block", md: "none" } }}
            >
              Show Comments
            </Button>
            <CommentsBackdrop
              className={`${expandMobileComments ? "active" : ""}`}
            >
              <CommentsContainer>
                <Box
                  sx={{
                    display: "flex",
                    py: 2,
                    zIndex: 9,
                    // background: "#fff",
                    top: "-11px",
                    position: { xs: "sticky", md: "static" },
                  }}
                >
                  <Button
                    onClick={handleMobileComments}
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    sx={{ mr: "auto", display: { md: "none" } }}
                  >
                    Back to video
                  </Button>
                  <Typography
                    variant="subtitle2"
                  >
                    {currentVideo?.count}{" "}
                    {currentVideo?.count > 1 ? "Comments" : "Comment"}
                  </Typography>
                </Box>
                <Comments videoId={currentVideo?._id} />
              </CommentsContainer>
            </CommentsBackdrop>
        </div>
      )}
      <aside className="recommended-videos-sidebar">
        <Recommendation tags={currentVideo?.tags} />
      </aside>
      {open && <Modal setOpen={setOpen} url={window.location.href} />}
      {openStripeModal && (
        <StripeModal
          setOpenModal={setOpenStripeModal}
          videoId={currentVideo?._id}
          channelName={channel.name}
        />
      )}
      {openReportModal && (
        <ReportModal
          setOpenModal={setOpenReportModal}
          videoId={currentVideo?._id}
          channelName={channel.name}
        />
      )}
    </div>
  );
}

export default Video;

  //  clean up video page fix left out styling 
