import { Box, Avatar, Typography, Skeleton } from "@mui/material";

const SearchCardSkeleton = () => (
  <Box sx={{ display: { md: 'flex'} }}>
    <Box>
      <Skeleton
        variant="rectangular"
        sx={{
          height: 200,
          width: 360
        }}></Skeleton>
    </Box>
    <Box sx={{ width: '100%', px: 3 }}>
      <Skeleton width="70%" sx={{ mb: 1 }}>
        <Typography>.</Typography>
      </Skeleton>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Skeleton variant="circular" height={22} width={22}>
          <Avatar />
        </Skeleton>
        <Skeleton width={75} sx={{ ml: 2 }}>
          <Typography>.</Typography>
        </Skeleton>
      </Box>
      <Skeleton width={150}>
        <Typography>.</Typography>
      </Skeleton>
      <Skeleton width="70%"></Skeleton>
      <Skeleton width="70%"></Skeleton>
    </Box>
  </Box>
);

export default SearchCardSkeleton;
