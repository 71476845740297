import { useState } from "react";
import { Button, Paper } from "@mui/material";
import CommentReply from "./CommentReply";
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import { useTheme } from "styled-components";

const RepliesList = ({ replies, ...rest }) => {
  const [hideRepliesList, setHideRepliesList] = useState(true);

  const handleHideRepliesList = () => setHideRepliesList((prev) => !prev);

  const theme = useTheme();

  return (
    <div className="replies-block">
      <Button
        size="small"
        sx={{ textTransform: "none", color: 'var(--text-main)' }}
        onClick={handleHideRepliesList}
        startIcon={
          hideRepliesList ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
        }
      >
        {replies.length} {replies.length > 1 ? "Replies" : "Reply"}
      </Button>
      <div
        sx={{ p: 2, backgroundColor:'var(--primary-color)' }}
        hidden={hideRepliesList}
        variant="outlined"
      >
        {replies.map((reply) => (
          <CommentReply key={reply._id} reply={reply} {...rest} />
        ))}
      </div>
    </div>
  );
};

export default RepliesList;
