import React, { useState } from "react";
import styled from "styled-components";
import { SocialIcon } from "react-social-icons";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.655);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  background-color: var(--secondary-light-color);
  color: var(--text-main);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: grey;
  font-size: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  color: var(--text-main);
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const SocialIcons = styled(SocialIcon)`
  margin-right: 10px;
  color: var(--text-main);
`;

const StyledSocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Input = styled.input`
  border: 1px solid #a9a9a9;
  color: var(--text-main);
  border-radius: 4px;
  padding: 12px;
  background-color: transparent;
  width: 100%; /* Ensures it fills the container */
  font-size: 16px;
  margin-bottom: 20px;
  box-sizing: border-box; /* Ensures padding is included in width calculation */

  &:focus {
    outline: none;
    border-color: #3573d9;
  }
`;

const Button = styled.button`
  border-radius: 4px;
  border: none;
  padding: 12px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ copied }) => (copied ? "#28a745" : "#3f8efc")};
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1e7e34;
  }
`;

const Label = styled.label`
  font-size: 14px;
`;

const Modal = ({ setOpen, url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const snapchatUrl = `https://www.snapchat.com/add?url=${url}`;
  const whatsappUrl = `https://wa.me/?text=${url}`;
  const redditUrl = `https://www.reddit.com/submit?url=${url}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  const emailUrl = `mailto:?body=${url}`;

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpen(false)}>×</Close>
        <Title>Share Video</Title>
        <Label copied={isCopied}>Copy Video</Label>

        <StyledSocialIconsContainer>
          <SocialIcons url={twitterUrl} />
          <SocialIcons url={snapchatUrl} />
          <SocialIcons url={whatsappUrl} network="whatsapp" />
          <SocialIcons url={redditUrl} />
          <SocialIcons url={facebookUrl} />
          <SocialIcons url={linkedinUrl} />
          <SocialIcons url={emailUrl} />
        </StyledSocialIconsContainer>

        <Input
          type="text"
          readOnly
          value={url}
          onClick={handleCopy}
          onChange={() => setIsCopied(false)}
          placeholder="Click to copy"
          copied={isCopied}
        />
        <Button onClick={handleCopy} copied={isCopied}>
          {isCopied ? "Copied" : "Copy"}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default Modal;
