import React from "react";
import "../../css/terms.css";

const TermsOfService = () => {
  return (
      <div className="content">
        <h1 className="heading">Terms Of Service</h1>
        <p className="date">
          Date Modified: <time dateTime="2024-08-26">[August 26, 2024]</time>
        </p>
        <p className="description">
          Welcome to Yivster! These Terms of Service ("Terms") govern your use
          of the Yivster platform, including our website, mobile apps, and
          related services (collectively, the "Services"). By accessing or using
          the Services, you agree to be bound by these Terms. If you do not
          agree to these Terms, you must not use our Services.
        </p>

        <h2 className="subHeading">1. Account Requirements</h2>
        <h3 className="subSubHeading">1.1 Eligibility</h3>
        <p className="description">
          You must be at least 13 years old, or the minimum age required to
          provide digital consent in your jurisdiction, to use Yivster. If you
          are under 18, you must have the permission and direct supervision of a
          parent or legal guardian to use the Services. By creating an account,
          you affirm that you are either 18 years old or older or that you have
          obtained parental or guardian consent to use Yivster.
        </p>

        <h3 className="subSubHeading">1.2 Registration</h3>
        <p className="description">
          To access certain features of Yivster, you need to create an account.
          You must provide accurate and complete information when creating your
          account and keep your account information up to date. You are
          responsible for maintaining the confidentiality of your account login
          credentials and are liable for any activities that occur under your
          account.
        </p>

        <h3 className="subSubHeading">
          1.3 Account Suspension and Termination
        </h3>
        <p className="description">
          Yivster reserves the right to suspend or terminate your account at any
          time, without notice, for any violation of these Terms or other
          applicable policies, including our Community Guidelines and Privacy
          Policy. If your account is terminated, you may lose access to any
          content or data associated with your account.
        </p>

        <h2 className="subHeading">2. User Content</h2>
        <h3 className="subSubHeading">
          2.1 Responsibility for User Content
        </h3>
        <p className="description">
          You retain ownership of any content you create and share on Yivster,
          including videos, comments, and other media ("Your Content"). However,
          by uploading or posting Your Content on Yivster, you grant us a
          worldwide, non-exclusive, royalty-free, transferable, sublicensable
          license to use, reproduce, distribute, modify, display, and perform
          Your Content in connection with the operation of the Services. You are
          solely responsible for Your Content and any consequences of sharing it
          on Yivster. You represent and warrant that you have the necessary
          rights to upload and share Your Content and that it does not violate
          any laws or infringe the rights of others.
        </p>

        <h3 className="subSubHeading">2.2 Community Guidelines</h3>
        <p className="description">
          All users of Yivster must comply with our Community Guidelines, which
          outline acceptable behavior and prohibited content on the platform.
          Violation of these guidelines may result in content removal, account
          suspension, or other penalties.
        </p>

        <h3 className="subSubHeading">2.3 Content Removal</h3>
        <p className="description">
          Yivster reserves the right to remove or disable access to any content
          that violates these Terms, our Community Guidelines, or applicable
          laws. We may also remove content that we deem inappropriate, harmful,
          or otherwise objectionable, at our sole discretion.
        </p>

        <h2 className="subHeading">3. Intellectual Property</h2>
        <h3 className="subSubHeading">
          3.1 Yivster’s Intellectual Property
        </h3>
        <p className="description">
          The Services and all content, design, graphics, software, and other
          elements provided by Yivster are owned by or licensed to Yivster and
          are protected by intellectual property laws. You may not use Yivster’s
          content or trademarks without our express written permission, except
          as permitted under these Terms.
        </p>

        <h3 className="subSubHeading">3.2 Copyright Infringement</h3>
        <p className="description">
          Yivster respects the intellectual property rights of others and
          expects users to do the same. If you believe that your work has been
          copied in a way that constitutes copyright infringement, please notify
          us in accordance with our DMCA policy.
        </p>

        <h2 className="subHeading">4. Prohibited Conduct</h2>
        <ul className="list">
          <li className="listItem">
            <span className="highlight">Illegal Activities:</span> You may
            not use Yivster to engage in illegal activities or to promote or
            facilitate illegal activities.
          </li>
          <li className="listItem">
            <span className="highlight">Harassment and Bullying:</span>{" "}
            Harassment, threats, or bullying of other users is strictly
            prohibited.
          </li>
          <li className="listItem">
            <span className="highlight">Hate Speech:</span> Content that
            promotes hatred, violence, or discrimination based on race,
            ethnicity, religion, gender, sexual orientation, or any other
            characteristic is not allowed.
          </li>
          <li className="listItem">
            <span className="highlight">Spam and Deceptive Practices:</span>{" "}
            You may not use Yivster to distribute spam, engage in deceptive
            practices, or manipulate engagement metrics.
          </li>
        </ul>

        <h2 className="subHeading">5. Payments and Subscriptions</h2>
        <p className="description">
          Yivster uses Stripe to process payments for services, subscriptions,
          and other transactions on the platform. By making payments through
          Yivster, you agree to Stripe’s terms of service and privacy policy. If
          you subscribe to any paid services on Yivster, you agree to pay all
          applicable fees and taxes associated with your subscription. Yivster
          reserves the right to change subscription fees with notice to you.
        </p>

        <h2 className="subHeading">6. Limitation of Liability</h2>
        <p className="description">
          Yivster provides the Services on an "as-is" and "as-available" basis.
          We make no warranties or representations about the accuracy,
          reliability, or completeness of the Services or any content on the
          platform. To the fullest extent permitted by law, Yivster shall not be
          liable for any indirect, incidental, consequential, or punitive
          damages arising from your use of the Services, even if we have been
          advised of the possibility of such damages.
        </p>

        <h2 className="subHeading">7. Indemnification</h2>
        <p className="description">
          You agree to indemnify and hold harmless Yivster, its affiliates, and
          their respective officers, directors, employees, and agents from any
          claims, liabilities, damages, losses, and expenses (including
          reasonable attorney’s fees) arising out of or in connection with your
          use of the Services, your violation of these Terms, or your violation
          of any rights of another.
        </p>

        <h2 className="subHeading">8. Changes to the Terms</h2>
        <p className="description">
          Yivster may update these Terms from time to time to reflect changes in
          our practices or for other operational, legal, or regulatory reasons.
          We will notify you of any significant changes by posting the new Terms
          on Yivster and, where appropriate, by other means. Your continued use
          of the Services after any such changes signifies your acceptance of
          the updated Terms.
        </p>

        <h2 className="subHeading">9. Governing Law</h2>
        <p className="description">
          These Terms shall be governed by and construed in accordance with the
          laws of the State of California, without regard to its conflict of law
          principles. Any legal actions or disputes arising out of these Terms
          or your use of Yivster shall be brought exclusively in the state or
          federal courts located in the State of California.
        </p>

        <h2 className= "subHeading">10. Contact Us</h2>
        <p className="description">
          If you have any questions or concerns about these Terms or the
          Services, please contact us at{" "}
          <a href="mailto:[insert contact email]" className="link">
            corporate@yivster.com
          </a>
          .
        </p>
      </div>
  );
}

export default TermsOfService;
