import React from 'react'
import Privacy from "../components/Legal/Privacy";

function PrivacyPolicy() {
  return (
    <div className="mainContent">
        <Privacy/>
    </div>
  )
}

export default PrivacyPolicy