import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, Divider } from "@mui/material";

import SearchVideoCard from "./SearchVideoCard";
import SearchCardSkeleton from "./SearchCardSkeleton";
import SearchChannelCard from "./SearchChannelCard";
import SearchFilters from "./SearchFilters";
import NoSearchData from "./NoSearchData";
import { generateKey } from "../../utils/helpers";
import axiosClient from "../../utils/client";
import { BASE_URL } from "../../config";
import { useTheme } from "styled-components";

const Search = () => {
  const [videos, setVideos] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const query = useLocation().search;

  useEffect(() => {
    fetchVideos(query);
  }, [query]);

  const fetchVideos = async (query = "", filters = {}) => {
    setIsLoading(true);
     const res = await axiosClient.get(`${BASE_URL}api/videos/search`, {
      params: {
        query,
        ...filters,
      },
    }); 
    setVideos(res.data.filter(({ type }) => type === "video"));
    setChannels(res.data.filter(({ type }) => type === "channel"));
    setIsLoading(false);
  };

  return (
    <Box sx={{ maxWidth: 1000, margin: "0 auto" }}>
      <SearchFilters query={query} fetchVideos={fetchVideos} />
      <Box
        sx={{
          maxWidth: { xs: 390, md: 1000 },
          margin: "0 auto",
        }}
        className="search-wrapper"
      >
        {isLoading &&
          generateKey("SearchCardSkeleton", 6).map((key) => (
            <Box sx={{ mb: 3 }} key={key}>
              <SearchCardSkeleton />
            </Box>
          ))}
        {channels.map((channel) => (
          <SearchChannelCard key={channel._id} channel={channel} />
        ))}
        <Divider />
        {videos.map((video) => (
          <SearchVideoCard key={video._id} video={video} />
        ))}
        {!isLoading && videos.length === 0 && <NoSearchData />}
      </Box>
    </Box>
  );
};

export default Search;
