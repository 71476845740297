
import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Box, Button, Avatar, Grid, TextField, Link } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginFailure, loginSuccess, loginStart } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../utils/values";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import "../css/signup.css";
import Logo from "../images/logo.png";
import {
  signupStart,
  signupFailure,
} from "../redux/userSlice";
import Error from "./Error";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Signup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState(null);
    const [signUpError, setsignUpError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [userType, setUserType] = useState(null);
    const [isDiabled, setIsDisabled] = useState(true);
    const [isChecked, setIsChecked] = useState(null);
    const [toShowCheckBox, setToShowCheckBox] = useState(false);
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    
    useEffect(() => {
      // console.log('userType', userType)
      // console.log('isDiabled', isDiabled)
      // console.log('isChecked', isChecked)
      if (userType === "user") {
        setToShowCheckBox(false);
        setIsDisabled(false);
      } else if (userType === "vendor") {
        setToShowCheckBox(true);
      }
      if (userType === "vendor" && isChecked) {
        // console.log('asdasdasd')
        setIsDisabled(false);
        // console.log('isDiabled*******', isDiabled)
      }
    }, [userType, isDiabled, isChecked]);
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      dispatch(signupStart());
  
      try {
        const res = await axiosClient.post("/api/auth/signup", {
          name,
          username,
          email,
          password,
          userType,
        });
  
        // console.log('***********', res.data);
        setSuccess(res.data);
  
        if (res.data) {
          // console.log('userType', userType)
  
          if (userType === "vendor") {
            const createAccount = await axiosClient.post("/api/auth/create/account", {
              email,
            });
            console.log("createAccount.data", createAccount.data);
            if (createAccount.data) {
              window.open(createAccount.data.url, "_blank");
            }
          }
        }
      } catch (err) {
        setsignUpError(err.response.data.message);
        dispatch(signupFailure());
        setTimeout(() => {
          setsignUpError(false);
        }, 10000);
        // console.log(err);
        // console.log(err.response.data);
        // console.log(err);
        // console.log(err.response.data);
      }
    };
  
    const handleOpen = () => setIsOpen(true);

    const handleClose = () => {
      setIsOpen(false);
      navigate("/");
    }

    const handleSignIn = (event) => {
      event.preventDefault();
      // Handle sign-in here
    };


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
    {isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>&times;</span>
          <div className="logo-container">
            <img src={Logo} alt="Yivster Logo" />
            <h2>Join Yivster today</h2>
          </div>
          <form className="signUp-form">
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input 
                className="styled-input"
                required
                id="username"
                name="username"
                autoComplete="username"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <p className="input-info">This is how others will see you on Yivster.</p>
            </div>
            
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input 
                className="styled-input"
                required
                name="password"
                type="password"
                id="password"
                autoComplete="new-password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input 
                className="styled-input"
                required
                id="name"
                name="name"
                autoComplete="name"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input 
                className="styled-input"
                required
                name="email"
                type="email"
                id="email"
                autoComplete="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            
            <div className="radio-group">
              <label>
                <input 
                  type="radio" 
                  name="userType" 
                  value="vendor" 
                  onChange={(e) => setUserType(e.target.value)} 
                />
                Creator
              </label>
              <label>
                <input 
                  type="radio" 
                  name="userType" 
                  value="user" 
                  onChange={(e) => setUserType(e.target.value)} 
                />
                User
              </label>
            </div>
            
            {toShowCheckBox && (
              <div className="checkbox-group">
                <label>
                  <input 
                    type="checkbox" 
                    name="agree" 
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  I agree this will redirect to the stripe page.
                </label>
              </div>
            )}
            
            {signUpError && <Error>{signUpError}</Error>}
            {success && <Error variant="success">{success}</Error>}
            
            <button className="sign-up-btn" type="submit" onClick={handleSignUp} >Sign Up </button>
            
            <p className="terms">
              By clicking Sign Up, you are agreeing to Yivster's{' '}
              <Link to="/terms" style={{ textDecoration:"none", color:"rgb(145, 71, 255)"}} className="terms-Tos" component={RouterLink}>
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link to="/privacy-policy"  style={{ textDecoration:"none" ,color:"rgb(145, 71, 255)"}} className="terms-PP" component={RouterLink}>
                Privacy Policy
              </Link>
                .
              </p>
          </form>
            <p className="account"> Have An Account Already? <Link to="/signin"  style={{textDecoration: "none", color:"#9147ff"}} component={RouterLink}> Login </Link></p>
        </div>
      </div>
    )}
    </>
  );
};

export default Signup;
