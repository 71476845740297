import axios from 'axios';

const axiosClient = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  const csrftoken = localStorage.getItem('ServiceToken');
  if (csrftoken) {
    config.headers = { ...config.headers, 'authorization': csrftoken };
  }
  return config;
});

export default axiosClient;
