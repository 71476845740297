import React, { useState } from "react";
import { Box, Button, Avatar, Grid, TextField, Link } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { loginFailure, loginSuccess, loginStart } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link as RouterLink, useNavigate} from "react-router-dom";
import { ROUTES } from "../utils/values";
import Error from "./Error";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import "../css/signin.css";
import Logo from "../images/logo.png";


const SignInModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();
    //loading login state
    dispatch(loginStart());
    try {
      const res = await axiosClient.post("/api/auth/jwt/signin", {
        email,
        password,
      });
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userPassword", password);
      localStorage.setItem("ServiceToken", res?.data?.serviceToken);
      dispatch(loginSuccess(res.data));
    } catch (err) {
      setError(err.response.data.message);
      dispatch(loginFailure());
    }
  };

  if (currentUser) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  const handleOpen = () => setIsOpen(true);
  // const handleClose = () => setIsOpen(false);
  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  }
  const handleSignIn = (event) => {
    event.preventDefault();
    // Handle sign-in here
  };
    
      return (
        <>
        {isOpen && (
          <div className="signin-modal-overlay">
            <div className="signin-modal-content">
              <section className="forms-container">
                <div className="form login">
                  <div className="form-content">
                    <span className="signin-close" onClick={handleClose}>&times;</span>
                    <div className="signin-logo-container">
                      <img src={Logo} alt="Yivster Logo" />
                      <h2>Log in to Yivster</h2>
                    </div>
                    <form onSubmit={handleSignIn}>
                      <div className="field input-field">
                        <label htmlFor="signin-email">Email Address</label>
                        <input 
                          placeholder="Email" 
                          className="input"
                          required
                          fullWidth
                          name="signin-email"
                          type="email"
                          id="signin-email"
                          autoComplete="current-email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="field input-field">
                        <label htmlFor="signin-password">Password</label>
                        <input 
                          type="password" 
                          placeholder="Password" 
                          className="password"
                          required
                          fullWidth
                          name="password"
                          id="password"
                          autoComplete="current-password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {error && <div className="error-message">{error}</div>}
                      <div className="field button-field">
                        <button type="submit" onClick={handleLogin}>Log In</button>
                      </div>
                    </form>
                    <div className="form-link">
                      <span>Don't have an account? <Link to="/signup" component={RouterLink} className="signin-link">Sign Up</Link></span>
                    </div>
                    {/* <div className="form-link">
                      <a href="#" className="forgot-pass">Trouble logging in?</a>
                    </div> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </>

      );
    }
  
export default SignInModal;
