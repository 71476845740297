import { useState } from "react";
import { Box, Link, Typography, Skeleton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import HoverVideoPlayer from "react-hover-video-player";
import { format } from "timeago.js";
import { useTheme } from "styled-components";

import {
  Thumbnail,
  ChannelAvatar,
  VideoContainer,
  ChannelContainer,
  StyledDescription,
} from "./styles";

const SearchVideoCard = ({ type, video }) => {
  const theme = useTheme();

  const [imageIsLoaded, setImageLoaded] = useState(false);

  const handleLoading = () => {
    setImageLoaded(true);
  };

  return (
    <VideoContainer className="video-container">
      <Link
        to={`/video/${video?._id}`}
        underline="hover"
        component={RouterLink}
      >
        <Thumbnail>
          <HoverVideoPlayer
            type={type}
            videoSrc={video?.videoUrl}
            pausedOverlay={
              <>
                {!imageIsLoaded && (
                  <Skeleton
                    sx={{
                      backgroundColor: "blue",
                      height: 190,
                      position: "absolute",
                      backgroundColor: "background.paper",
                      width: "100%",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                )}
                <img
                  src={video?.imgUrl}
                  alt={video?.title}
                  onLoad={handleLoading}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    opacity: imageIsLoaded ? "1" : "0",
                  }}
                />
              </>
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
          />
        </Thumbnail>
      </Link>
      <Box sx={{ ml: 3, py: { sm: 2, md: 0 } }}>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "1.125rem", color: "var(--main-text-color)"}}
        >
          <Link
            to={`/video/${video._id}`}
            underline="hover"
            component={RouterLink}
            style={{ color: "var(--main-text-color)" }}
          >
            {video.title}
          </Link>
        </Typography>
        <Link
          to={`/channel/${video?.userData?._id}`}
          underline="hover"
          component={RouterLink}
        >
          <ChannelContainer sx={{ mb: 1 }}>
            <ChannelAvatar
              type={type}
              src={video?.userData?.img}
              alt={video?.userData?.name}
            />
            <Typography
              variant="subtitle2"
              sx={{
                ml: 1,
                textTransform: "capitalize",
                color: "var(--main-text-color)",
              }}
            >
              {" "}
              {video?.userData?.name}
            </Typography>
          </ChannelContainer>
        </Link>
        <Typography variant="body2" sx={{ color: "var(--main-text-color)" }}>
          {video?.views} views • {format(video?.createdAt)}{" "}
        </Typography>
        <StyledDescription
          sx={{ pt: 2, fontSize: "0.9rem", color: "var(--main-text-color)" }}
        >
          {video?.desc}
        </StyledDescription>
      </Box>
    </VideoContainer>
  );
};

export default SearchVideoCard;
