import {
  Box,
  Typography,
} from "@mui/material";

import SearchingData from "../../assets/images/searching-data.png";

const NoSearchData = () => (
  <Box className="no-search-data" sx={{ textAlign: 'center' }}>
  <img src={SearchingData} style={{ maxWidth: '600px' }} alt="Searching" />
  <Typography variant="h4" pb={1}>No results found</Typography>
  <Typography>Try different keywords</Typography>
</Box>
);

export default NoSearchData;
