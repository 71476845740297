import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { format } from "timeago.js";
import { useTheme } from "styled-components";
import HoverVideoPlayer from 'react-hover-video-player';
import {Avatar} from "@mui/material";
import "../css/card.css";

const Card = ({ toShow, type, video }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [imageIsLoaded, setImageLoaded] = useState(false);


  const getVideoDuration = (duration) => {
    let returnValue = "00:00";
    if (duration) {
      // let minutes = Math.floor(duration / 60);
      // let seconds = Math.floor(duration % 60);
      // minutes = minutes.toString().padStart(2, '0');
      // seconds = seconds.toString().padStart(2, '0');
      // returnValue = `${parseInt(minutes)} : ${parseInt(seconds)}`

      returnValue = new Date(duration * 1000).toLocaleTimeString([], {
        minute: "numeric",
        second: "2-digit",
      });
    }
    return returnValue;
  };

  const handleLoading = () => {
    setImageLoaded(true);
  };

  const theme = useTheme();

      return (
        <div className="video-card" type={type}>
          <div className="thumbnail-container">
            <Link to={`/video/${video._id}`} className="card-link">
              <HoverVideoPlayer
                videoSrc={video.videoUrl}                
                pausedOverlay={
                  <>
                    {!imageIsLoaded && (
                      <Skeleton 
                        variant="rectangular" 
                        width="100%" 
                        height="100%" 
                        animation="wave"
                      />
                    )}
                <img
                    className="video-thumbnail"
                    src={video.imgUrl}
                    onLoad={handleLoading}
                  />
              </>
            }
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            }
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </Link>
        <span className="video-duration">{getVideoDuration(video.duration)}</span>
      </div>
      <div className="video-info">
        <Link to={`/channel/${video?.userData?._id}`}>
          <Avatar className="channel-avatar" src={video?.userData?.img} />
        </Link>
        <div className="video-text">
          <Link  style={{ textDecoration: "none", color: "var(--text-main)" }} to={`/video/${video._id}`}>
            <h3 className="video-title">{video.title}</h3>
          </Link>
          <Link style={{ textDecoration: "none", color: "inherit" }} to={`/channel/${video?.userData?._id}`}>
            <p className="video-channel">{video?.userData?.name}</p>
          </Link>
          <p className="video-metadata">{video.views} views • {format(video.createdAt)}</p>
        </div>
      </div>
    </div>
      );
    };

export default Card;
