import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Comments from "../components/Comments";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchSuccess, like, dislike } from "../redux/videoSlice";
import { updateUser } from "../redux/userSlice";
import { format } from "timeago.js";
import { subscription } from "../redux/userSlice";
import Recommendation from "../components/Recommendation";
import { useCallback } from "react";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import { FirebaseError } from "firebase/app";
import { logout } from "../redux/userSlice";
import axiosClient from "../utils/client";

const Container = styled.div`
  display: flex;
`;

const Main = styled.div`
  flex: 7;
  background-color: ${({ theme }) => theme.bg};
`;

const ImgBanner = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const Banner = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  display: flex;
`;

const UserBanner = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChannelName = styled.span`
  font-weight: 500;
  display: flex;
  margin-left: 20px;
  color: ${({ theme }) => theme.textSoft};
`;

const Subscribers = styled.span`
  margin-left: 6px;
  color: ${({ theme }) => theme.textSoft};
`;

const UserName = styled.span`
  color: ${({ theme }) => theme.textSoft};
  margin-left: 6px;
`;
const VideoList = styled.span`
  color: ${({ theme }) => theme.textSoft};
  margin-left: 6px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 50%;
`;

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.textSoft};
  border-bottom: 1px solid ${({ theme }) => theme.textSoft};
  background-color: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.text};
  padding: 10px;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
`;

const Button = styled.button`
  border: none;
  background-color: #9553b5;
  color: white;
  padding: 10px;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const DeleteButton = styled.button`
  border-radius: 3px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: Red;
  color: white;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.textSoft};
  font-size: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Video = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
`;

const VideoImage = styled.img`
  width: 200px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const VideoTitle = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.text};
`;

const VideoViews = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const VideoTimestamp = styled.span`
  color: ${({ theme }) => theme.textSoft};
`;

const VideoDescription = styled.span`
  color: ${({ theme }) => theme.text};
  margin-top: 20px;
`;

const Dashboard = ({}) => {
  //const [name, setName] = useState("");
  //const [email, setEmail] = useState("");
  const [img, setImg] = useState(undefined);
  const [inputs, setInputs] = useState({});
  //const [password, setPassword] = useState("");
  const [imgPerc, setImgPerc] = useState(0);

  const { currentUser } = useSelector((state) => state.user);
  const { currentVideo } = useSelector((state) => state.video);

  const dispatch = useDispatch();

  //getting the path which is the video ID
  const path = useLocation().pathname.split("/")[2];

  const [channel, setChannel] = useState({});
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //fetching channel info from backend
        const channelRes = await axiosClient.get(`/api/users/find/${path}`);

        //dispatch(fetchSuccess(channelRes.data));
        //console.log(channelRes.data);

        //fetching video info from backend
        const videoRes = await axiosClient.get(
          `/api/videos/channel/${channelRes.data._id}`
        );
        setVideos(videoRes.data);
        dispatch(fetchSuccess(videoRes.data));

        setChannel(channelRes.data);
        //console.log(channelRes.data);
        //send video payload to backend
        // dispatch(fetchSuccess(videoRes.data));
      } catch (err) {}
    };
    fetchData();
  }, [path, dispatch]);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await axiosClient.delete(`/api/users/delete/${currentUser._id}`);
      dispatch(logout());
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const uploadFile = (file, urlType) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // if its image url uodate image percantange if not set video percentage
        if (urlType === "img") {
          setImgPerc(Math.round(progress));
        } else {
          return null;
        }
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error.serverResponse.data);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            return { ...prev, [urlType]: downloadURL };
          });
        });
      }
    );
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await axiosClient.put(
        `/api/users/dashboard/${currentUser._id}`,
        { ...inputs }
      );
      dispatch(updateUser(updatedUser.data));
    } catch (err) {
      console.log(err.response.data);
    }
  };

  /*
      todo
      fix firebase from uploading before the user has selected a file and pressed the upload button */

  useEffect(() => {
    // if there is a image call the upload function and passing in the video
    img && uploadFile(img, "img");
    // whenever we add a new image file it will fire this function
  }, [img]);

  /*     const handleImage = async (e) => {
        e.preventDefault();
        try {
            const file = e.target.files[0];
            if (!file) return alert("File not exist.");
            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!");
            if (file.type !== "image/jpeg" && file.type !== "image/png")
                // 1mb
                return alert("File format is incorrect.");
            let formData = new FormData();
            formData.append("file", file);
            setImgPerc(0);
            const res = await axios.post("/api/upload", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
                onUploadProgress: (ProgressEvent) => {
                    const { loaded, total } = ProgressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    if (percent < 100) {
                        setImgPerc(percent);
                    }
                },
            });
            setImg(res.data.url);
        } catch (err) {
            alert(err.response.data.msg);
        }
    }; */

  return (
    <Container>
      <Main>
        <ImgBanner>
          <Banner src={channel.img} />
        </ImgBanner>

        <UserBanner>
          <Image src={channel.img} />
          <HeaderInfo>
            <ChannelName> {channel.name} </ChannelName>
            <UserName> {channel.username} </UserName>
            <Subscribers> {channel.subscribers} subscribers </Subscribers>
            <VideoList> {videos.length} videos </VideoList>
          </HeaderInfo>
        </UserBanner>

        <Form>
          <Label> Name </Label>
          <Input
            type="text"
            placeholder="Enter your name"
            name="name"
            onChange={handleChange}
          />

          <Label> Username </Label>
          <Input
            type="text"
            name="username"
            placeholder="Enter your name"
            onChange={handleChange}
          />

          <Label> Email </Label>
          <Input
            type="email"
            name="email"
            placeholder="Enter your email"
            onChange={handleChange}
          />
          <Label> Password </Label>
          <Input
            type="password"
            name="password"
            placeholder="Enter your password"
            onChange={handleChange}
          />
          <Label> Profile Picture </Label>
          {imgPerc > 0 ? (
            "Uploading:" + imgPerc + "%"
          ) : (
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => setImg(e.target.files[0])}
            />
          )}

          <Button onClick={handleUpdate}> Update Account </Button>

          {/* {picMessage && <span style={{ color: "red", marginTop: "10px" }}> {picMessage} </span>} */}

          <DeleteButton onClick={handleDelete}> Delete Account </DeleteButton>
        </Form>
      </Main>
    </Container>
  );
};

export default Dashboard;
