import { Typography, Grid, Box, Paper } from "@mui/material";
import {
  CalendarTodayOutlined as CalendarTodayOutlinedIcon,
  VideocamOutlined as VideocamOutlinedIcon,
TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import Moment from 'react-moment';
// import moment from 'moment';

import { SocialIcon } from 'react-social-icons';
import { nanoid } from "@reduxjs/toolkit";

const ChannelAbout = ({channel, totalVideos, totalViews}) => (

  <>
    <Grid container spacing={2} style={{minHeight: "100vh", marginTop:"15px"}}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{ p: 3, backgroundColor:"var(--secondary-light-color)"}} variant="outlined">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"var(--text-main)" }}>Description</Typography>
          <Typography sx={{ mb: 3, color:"var(--text-main)" }}>
            {channel?.channelInfo?.description}
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color:"var(--text-main)" }}>Info</Typography>
          <Typography style={{ color:"var(--text-main)"}}>{channel?.channelInfo?.info.location}</Typography>
          <Typography sx={{ mb: 3, color:"var(--text-main)" }}>{channel?.channelInfo?.info.inquiries}</Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold',  color:"var(--text-main)" }}>Socials</Typography>
          {
            Object.keys(channel?.channelInfo?.socials).map((key) => {
              const value = channel?.channelInfo?.socials[key];
              return value !== '' ? (
                <Box key={nanoid()} sx={{ mb: 1 }}>
                  <SocialIcon url={value} />
                  <Typography variant="subtitle1" component="span" sx={{ textTransform: "capitalize", ml: 1,  color:"var(--text-main)" }}>{key}</Typography>
                </Box>
              ) : ''
            })
          }
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper sx={{ p: 3, backgroundColor:"var(--secondary-light-color)"}} variant="outlined">
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color:"var(--text-main)" }}>Additional Details</Typography>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <CalendarTodayOutlinedIcon sx={{ mr: '10px', color:"var(--text-main)" }} />
            <Typography variant="subtitle2" component="span" sx={{ flexGrow: 1, color:"var(--text-main)" }}>
              Join On <Moment format="MMM DD, YYYY" style={{color:"var(--text-main)"}}>{channel?.createdAt}</Moment>
              {/* Join On  */}
            </Typography>
          </Box>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <VideocamOutlinedIcon sx={{ fontSize: 26, mr: 1, color:"var(--text-main)" }} />
            <Typography variant="subtitle2" component="span" sx={{ flexGrow: 1, color:"var(--text-main)" }}>{totalVideos} Videos</Typography>
          </Box>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <TrendingUpIcon sx={{ fontSize: 26, mr: 1, color:"var(--text-main)" }} />
            <Typography variant="subtitle2" component="span" sx={{ flexGrow: 1, color:"var(--text-main)" }}>{totalViews} Views</Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </>
)

export default ChannelAbout;
