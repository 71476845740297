import { useState } from "react";
import {
  Box,
  Grid,
  Dialog,
  Button,
  Checkbox,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Radio,
  List,
  Chip,
  ListItem,
  Divider,
  FormLabel,
} from "@mui/material";
import { useTheme } from "styled-components";

import { Close as CloseIcon, Tune as TuneIcon } from "@mui/icons-material";

const dateFilters = [
  "Last hour",
  "Today",
  "This week",
  "This month",
  "This year",
];
const viewsFilters = ["Heighest", "Lowest"];
const sortFilters = ["Ascending", "Descending"];

const SearchFilters = ({ query, fetchVideos }) => {
  const theme = useTheme();
  const [dateCheckedFilters, setDateCheckedFilters] = useState("");
  const [viewsCheckedFilters, setViewsCheckedFilters] = useState("");
  const [sortCheckedFilters, setSortCheckedFilters] = useState("");
  const [viewAllChannels, setViewAllChannels] = useState(false);
  const [isModalOpened, setModalOpen] = useState(false);

  const handleChannelChange = (event) => {
    setViewAllChannels(event.target.checked);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleApplyFilters = () => {
    const filters = {
      date: dateCheckedFilters.toLocaleLowerCase().replace(" ", "_"),
      views: viewsCheckedFilters.toLocaleLowerCase().replace(" ", "_"),
      sort: sortCheckedFilters.toLocaleLowerCase().replace(" ", "_"),
      allChannels: viewAllChannels,
    };
    fetchVideos(query, filters);
  };

  const clearAllFilters = () => {
    setDateCheckedFilters("");
    setViewsCheckedFilters("");
    setSortCheckedFilters("");
    setViewAllChannels(false);
    fetchVideos(query);
  };

  const handleChange = (value, setChecked) => () => {
    setChecked(value);
  };

  const isFilterSelected =
    dateCheckedFilters ||
    viewsCheckedFilters ||
    sortCheckedFilters ||
    viewAllChannels;

  return (
    <Box sx={{ py: 3 }}>
      <Box className="filter-bar" sx={{ display: "flex" }}>
        <Box sx={{ display: "none", gap: 2 }}>
          {dateCheckedFilters && (
            <Chip
              size="small"
              label={dateCheckedFilters}
              onDelete={() => setDateCheckedFilters("")}
            />
          )}
          {viewsCheckedFilters && (
            <Chip
              size="small"
              label={viewsCheckedFilters}
              onDelete={() => setViewsCheckedFilters("")}
            />
          )}
          {sortCheckedFilters && (
            <Chip
              size="small"
              label={sortCheckedFilters}
              onDelete={() => setSortCheckedFilters("")}
            />
          )}
          {viewAllChannels && (
            <Chip
              size="small"
              label="View All Channels"
              onDelete={() => setViewAllChannels(false)}
            />
          )}
        </Box>
        <Button
          sx={{ ml: "auto", color: "var(--main-text-color)"}}
          endIcon={<TuneIcon />}
          onClick={handleModalOpen}
        >
          Filters
        </Button>
      </Box>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={isModalOpened}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Search Filters
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minWidth: 500 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                sx={{ textTransform: "uppercase", pb: 1 }}
              >
                Upload Date
              </Typography>
              <Divider />
              <List>
                {dateFilters.map((label) => (
                  <ListItem key={label} disablePadding>
                    <FormLabel sx={{ cursor: "pointer" }}>
                      <Radio
                        checked={dateCheckedFilters === label}
                        onChange={handleChange(label, setDateCheckedFilters)}
                        id={`filter-${label}`}
                        value={label}
                        name="radio-buttons"
                        inputProps={{ "aria-label": label }}
                      />
                      <Typography component="span" variant="body2">
                        {label}
                      </Typography>
                    </FormLabel>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                sx={{ textTransform: "uppercase", pb: 1 }}
              >
                Views
              </Typography>
              <Divider />
              <List>
                {viewsFilters.map((label) => (
                  <ListItem key={label} disablePadding>
                    <FormLabel sx={{ cursor: "pointer" }}>
                      <Radio
                        checked={viewsCheckedFilters === label}
                        onChange={handleChange(label, setViewsCheckedFilters)}
                        id={`filter-${label}`}
                        value={label}
                        name="radio-buttons"
                        inputProps={{ "aria-label": label }}
                      />
                      <Typography component="span" variant="body2">
                        {label}
                      </Typography>
                    </FormLabel>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <FormLabel sx={{ cursor: "pointer" }}>
                    <Checkbox
                      checked={viewAllChannels}
                      onChange={handleChannelChange}
                      inputProps={{ "aria-label": "All Channels" }}
                    />
                    <Typography component="span" variant="body2">
                      View All Channels
                    </Typography>
                  </FormLabel>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                sx={{ textTransform: "uppercase", pb: 1 }}
              >
                Sort By
              </Typography>
              <Divider />
              <List>
                {sortFilters.map((label) => (
                  <ListItem key={label} disablePadding>
                    <FormLabel sx={{ cursor: "pointer" }}>
                      <Radio
                        checked={sortCheckedFilters === label}
                        onChange={handleChange(label, setSortCheckedFilters)}
                        id={`filter-${label}`}
                        value={label}
                        name="radio-buttons"
                        inputProps={{ "aria-label": label }}
                      />
                      <Typography component="span" variant="body2">
                        {label}
                      </Typography>
                    </FormLabel>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Button onClick={clearAllFilters}>Clear All</Button>
              <Button
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={handleModalClose}
              >
                Close
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                disabled={!isFilterSelected}
                onClick={handleApplyFilters}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SearchFilters;
