export const darkTheme = {
  palette: {
    main: {
      background: 'var(--primary-color)',
      text: 'var(--main-text-color)',
    },
  },
};

export const lightTheme = {
  palette: {
    main: {
      background: 'var(--primary-color)',
      text: 'var(--main-text-color)',
    },
  },
};