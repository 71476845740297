import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserPrefferedDarkTheme,
  setUserPrefferedLightTheme,
  setSystemPrefferedDarkTheme,
  setSystemPrefferedLightTheme,
} from "../redux/themeSlice";
import {
  Menu,
  User,
  LogOut,
  Video,
  Bell,
  Plus,
  MoreVertical,
  Check,
  Moon,
  Sun,
  Settings,
  HelpCircle,
  MessageSquare,
  X,
  DollarSign,
  Database,
  Globe,
  Key,
  SquareUserRound,
  Lock,
  ReceiptText,
  ClipboardList ,
  MapPin ,
  CircleUserRound,
  Palette
} from 'lucide-react';

// import {Avatar} from "@mui/material";
import Avatar from "../assets/images/default-avatar.jpeg"

import YivLogo from "../assets/images/logo.png";
import SearchBar from "./SearchBar";
import axiosClient from "../utils/client";
import { logout } from "../redux/userSlice";
import { DASHBOARD_URL } from "../defaultValues";

import '../css/navbar.css';

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [q, setQ] = useState("");
  const { darkMode, systemPreffered } = useSelector((state) => state.theme);
  const [isMobileSearchExpanded, setIsMobileSearchExpanded] = useState(false);


  const handleUploadRedirect = () => {
    const email = localStorage.getItem("userEmail");
    const password = localStorage.getItem("userPassword");
    window.open(
      `${DASHBOARD_URL}/login?email=${email}&password=${password}`,
      "_blank"
    );
  };

  const handleRedirect = () => {
    // handleClose();
    const email = localStorage.getItem("userEmail");
    const password = localStorage.getItem("userPassword");
    window.open(
      `${DASHBOARD_URL}/login?email=${email}&password=${password}`,
      "_blank"
    );
  };

/* 
  const handleLogout = async () => {
    try {
      await axiosClient.get("/api/users/logout");
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.log(err.response.data);
    }
  }; */

  const handleLogout = async () => {
    //handleClose();
    try {
      // await axiosClient.get("/api/users/logout");
      localStorage.removeItem('serviceToken');
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleSystemTheme = () => {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      dispatch(setSystemPrefferedDarkTheme());
    } else {
      dispatch(setSystemPrefferedLightTheme());
    }
  };

  const handleUserTheme = (mode) => {
    if (mode === "dark") {
      dispatch(setUserPrefferedDarkTheme());
    } else {
      dispatch(setUserPrefferedLightTheme());
    }
  };

  const handleMobileSearchExpand = () => {
    setIsMobileSearchExpanded(true);
  };

  const handleMobileSearchClose = () => {
    setIsMobileSearchExpanded(false);
  };

  

  const renderProfileMenu = () => {
    if (currentUser) {
      return (
        <div className="profile-menu">
          <div className="profile-header">
            {currentUser.img ? (
              <img src={currentUser.img} className="profile-pic" alt={currentUser.name} />
            ) : (
              <User />
            )}
            <div>
              <h3 style={{color:"var(--text-main)"}} >{currentUser.name}</h3>
              <p style={{color:"var(--text-main)"}}>@{currentUser.username}</p>
              <Link to={`/channel/${currentUser._id}`} style={{color:"#1E90FF", textDecoration:"none"}}> View your channel </Link>
            </div>
          </div>
          <ul className="menu-items">
            <li className="menu-divider"></li>
            <li onClick={handleRedirect} component={RouterLink} to="/"><CircleUserRound size={20} /> Dashboard</li>
            <li className="menu-divider"></li>
            <li onClick={handleLogout}><LogOut size={20} /> Sign out</li>
            <li className="menu-divider"></li>

            <Link to="/privacy-policy" style={{ textDecoration: "none", color: "inherit"  }}>
            <li><Lock size={20} /> Privacy Policy</li>
            </Link>

            <Link to="/terms" style={{ textDecoration: "none", color: "inherit"  }}>
              <li><ReceiptText size={20} /> Terms and Conditions</li>
           </Link>

           <Link to="/community-guidelines" style={{ textDecoration: "none", color: "inherit"  }}>
              <li><ClipboardList size={20} /> Community Guidelines</li>
            </Link>

            <li className="menu-divider"></li>
            <li onClick={() => handleUserTheme('light')}><Sun size={20} /> Light Mode</li>
            <li onClick={() => handleUserTheme('dark')}><Moon size={20} /> Dark Mode</li>
           <li onClick={handleSystemTheme}><Palette size={20} /> System Theme</li>
            {/* <li><Globe size={20} /> Language: English</li> */}
             {/*<li className="menu-divider"></li>
            <li><MapPin size={20} /> Location: {currentUser.channelInfo.info.location}</li> */}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="profile-menu">
          <ul className="menu-items">
            {/* <li className="menu-divider"></li> */}
            <Link to="/privacy-policy" style={{ textDecoration: "none", color: "inherit"  }}>
              <li><Lock size={20} /> Privacy Policy</li>
            </Link>

            <Link to="/terms" style={{ textDecoration: "none", color: "inherit"  }}>
              <li><ReceiptText size={20} /> Terms and Conditions</li>
            </Link>

            <Link to="/community-guidelines" style={{ textDecoration: "none", color: "inherit"  }}>
              <li><ClipboardList size={20} /> Community Guidelines</li>
            </Link>
            
            <li className="menu-divider"></li>
            <li onClick={() => handleUserTheme('light')}><Sun size={20} /> Light Mode</li>
            <li onClick={() => handleUserTheme('dark')}><Moon size={20} /> Dark Mode</li>
            <li onClick={handleSystemTheme}><Palette size={20} /> System Theme</li>
            {/* <li><Globe size={20} /> Language: English</li> */}
            {/* <li><Key size={20} /> Restricted Mode: Off</li> */}
            <li className="menu-divider"></li>
            <li><HelpCircle size={20} /> Help</li>
          </ul>
        </div>
      );
    }
  };


  return (
    <nav className={`navbar ${isMobileSearchExpanded ? 'search-expanded' : ''}`}>
    <div className="navbar-left">
      <div className="menu-icon-container">
        <Menu className="navbar-icon" onClick={toggleSidebar} />
      </div>
      <Link to="/" className="navbar-logo">
        <img src={YivLogo} alt="Yivster" className="youtube-logo" />
        <span className="country-code">BETA</span>
      </Link>
    </div>
    
    <div className="navbar-center">
      <SearchBar 
        query={q} 
        handleSearchQuery={setQ} 
        handleMobileSearchClose={handleMobileSearchClose}
      />
    </div>
    
    <div className="navbar-right">
      {currentUser ? (
        <>
          <div className="navbar-icons">
            <div className="tooltip-container">
              <Video className="navbar-icon" onClick={handleUploadRedirect} />
              <span className="tooltip">Upload</span>
            </div>
            <div className="tooltip-container">
              <div className="navbar-profile" onClick={toggleProfileMenu}>
                {currentUser.img ? (
                  <img src={currentUser.img} alt="Profile" className="profile-pic" />
                ) : (
                  <img src={Avatar} className="profile-pic"/>
                )}
              </div>
              <span className="tooltip">Account</span>
            </div>
          </div>
          {showProfileMenu && renderProfileMenu()}
        </>
      ) : (
        <div className="navbar-icons">
          <div className="tooltip-container">
            <MoreVertical className="navbar-icon" onClick={toggleProfileMenu} />
            <span className="tooltip">Settings</span>
          </div>
          <button className="signin-btn" onClick={() => navigate("/signin")}>
            <User className="user-icon" />
            <span>Sign In</span>
          </button>
        </div>
      )}
      {!currentUser && showProfileMenu && renderProfileMenu()}
    </div>
  </nav>
  );
};

export default Navbar;
