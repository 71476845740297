import React from "react";
import Card from "../components/Card";
import Navbar from "../components/Navbar";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import styled from "styled-components";
import CardSkeleton from "../components/CardSkeleton";
import { generateKey } from "../utils/helpers";
import axiosClient from "../utils/client";
// import AdBanner from "../assets/images/youtubeBanner.png";
import AdBanner from "../assets/images/3.png";
import YivLogo from "../assets/images/logo.png";
import axios from 'axios';
import "../css/home.css";

import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
} from "@mui/icons-material";

// import thumbnail from "../assets/images/thumbnail-1.webp";

import profilePic from "../assets/images/channel-1.jpeg";
const thumbnail = "https://firebasestorage.googleapis.com/v0/b/test-ea4f5.appspot.com/o/1708783421511Captura%20de%20pantalla%202024-02-24%20085548.JPEG?alt=media&token=2bc11954-746e-47d0-8bff-0c4461e585e4"
// const thumbnail = "https://d2ujxjy4fcrww5.cloudfront.net/1698050442876forever_my_preferred_person_and_idiot.JPEG"

const AuthRequiredBox = styled(Box)`
  display: flex;
  padding-top: 64px;
  justify-content: center;
  min-height: calc(100vh - 125px);
`;

const Home = ({type}) => {
  const categories = ['All', 'Music', 'Podcasts', 'Live', 'NBA', 'Mixes', 'Contemporary R&B', 'G-Funk', 'Gaming', 'Drill music', 'Freestyle Rap', 'Playlists', "Podcasts", "Movies", "TV", "Gaming"];

  const [videos, setVideos] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerTarget = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authIsRequired, setAuthIsRequired] = useState(false);

  const itemsPerPage = 12;

  const fetchVideos = async (page) => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      try {
        const res = await axiosClient.get(
          `/api/videos/${type}/${page}/${itemsPerPage}`
        );
        setHasMore(res?.data?.hasMore);
        setVideos((prev) => [...prev, ...res?.data?.data]);
      } catch ({ message }) {
        console.error(message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (authIsRequired) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prev) => {
            fetchVideos(prev);
            return prev + 1;
          });
        }
      },
      { threshold: 1 }
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, []);


  if (authIsRequired) {
    return (
      <AuthRequiredBox>
        <Box sx={{ textAlign: "center", minHeight: "100vh" }}>
          <SubscriptionsOutlinedIcon sx={{ fontSize: 100 }} />
          <Typography variant="h4">Don't miss new videos</Typography>
          <Typography sx={{ my: 2 }}>
            Sign in to see the latest videos from your subbed channels
          </Typography>
          <Button
            size="large"
            aria-label="account of current user"
            aria-haspopup="true"
            variant="outlined"
            sx={{ borderRadius: 5 }}
            onClick={() => navigate("/signin")}
            startIcon={<AccountCircleOutlinedIcon />}
          >
            SIGN IN
          </Button>
        </Box>
      </AuthRequiredBox>
    );
  }


  return (
    <div className="home-page">
      <div className="categories-container">
        {categories.map((category, index) => (
          <button key={index} className="category-item">
            {category}
          </button>
        ))}
      </div>

      <div className="video-grid">
          {videos.map((video) => (
            <Card video={video} />
          ))}
          {isLoading &&
            generateKey("CardSkeleton", itemsPerPage).map((key) => (
              <CardSkeleton />
            ))}
          <div ref={observerTarget}>&nbsp;</div>
      </div>
    </div>
  );
};


export default Home;
