import React, { useState } from "react";
import styled from "styled-components";
import YivLogo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { Home, SquarePlay, PlaySquare, User, TrendingUp, Clock4 } from 'lucide-react';
import "../css/sidebar.css";

const SidebarItem = ({ Icon, label, isActive, onClick }) => (
  <div 
    className={`sidebar-item ${isActive ? 'active' : ''}`} 
    onClick={onClick}
  >
    <Icon className={`sidebar-icon ${isActive ? 'active' : ''}`} />
    <span>{label}</span>
  </div>
);

const Sidebar = ({ isOpen, onClose }) => {
  const [activeItem, setActiveItem] = useState('Home');
  const location = useLocation();
  const isVideoPage = location.pathname.startsWith('/video');

  const handleItemClick = (label) => {
    setActiveItem(label);
    if (isVideoPage) {
      onClose();
    }
  };

  return (
     <>
      {isVideoPage && isOpen && <div className="sidebar-backdrop" onClick={onClose}></div>}
      <div className={`sidebar ${isOpen ? 'open' : ''} ${isVideoPage ? 'overlay' : ''}`}>
        <Link
            to="/"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
          <SidebarItem 
            Icon={Home} 
            label="Home" 
            isActive={activeItem === 'Home'}
            onClick={() => handleItemClick('Home')}
          />
        </Link>

        <Link
            to="/trending"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
        <SidebarItem 
          Icon={TrendingUp} 
          label="Trending" 
          isActive={activeItem === 'Trending'}
          onClick={() => handleItemClick('Trending')}
        />
        </Link>

        <Link
            to="/subscriptions"
            style={{ textDecoration: "none", color: "inherit"  }}
          >
          <SidebarItem 
            Icon={PlaySquare} 
            label="Subscriptions" 
            isActive={activeItem === 'Subscriptions'}
            onClick={() => handleItemClick('Subscriptions')}
          />
        </Link>

        <Link
            to="/newest"
            style={{ textDecoration: "none", color: "inherit"  }}
            >
          <SidebarItem 
            Icon={Clock4} 
            label="Newest" 
            isActive={activeItem === 'Newest'}
            onClick={() => handleItemClick('Newest')}
          />
        </Link>
      </div>
    </>
  );
};

export default Sidebar;
