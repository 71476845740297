export const generateKey = (pre = 'skeleton', count = 3) => {
  const keys = [];

  for (let i = 0; i < count; i++) {
    keys.push(`${ pre }_${ Math.round(Math.random()*100000000) } `)
  }

  return keys;
}

export default generateKey;