import { Grid, Paper, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import RecommendedCards from "./RecommendCards";
import axiosClient from "../utils/client";
import "../css/recommendation.css";


const VideoCard = styled(Paper)(({ theme, tags }) => ({
  flex: "1 1 250px", // Added: Flexbox properties to make cards equal width
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  "&:not(:last-child)": {
    marginBottom: "1rem",
  },
  "& img": {
    marginBottom: "0.5rem",
    width: "100%",
    borderRadius: "5px",
    objectFit: "cover",
  },
  "& .title": {
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  "& .info": {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    "& .views": {
      marginRight: "1rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    // Styles for screens smaller than 'sm' breakpoint
    flex: "1 1 150px",
    maxWidth: "270px", // Added: Limit the width of the Paper component
    maxHeight: "220px", // Added: Limit the height of the Paper component
    marginLeft: "10px",
    "& img": {
      marginBottom: "0.5rem",
      width: "100%",
      borderRadius: "5px",
      objectFit: "cover",
    },
    "& .title": {
      fontSize: "1rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    // Styles for screens larger than 'sm' breakpoint
    flex: "1 1 200px",
    maxWidth: "270px", // Added: Limit the width of the Paper component
    maxHeight: "220px", // Added: Limit the height of the Paper component
    marginLeft: "10px",
    "& img": {
      marginBottom: "0.5rem",
      width: "100%",
      borderRadius: "5px",
      objectFit: "cover",
    },
    "& .title": {
      fontSize: "1.2rem",
    },
  },
  [theme.breakpoints.up("lg")]: {
    // Styles for screens larger than 'md' breakpoint
    flex: "1 1 200px",
    maxWidth: "270px", // Added: Limit the width of the Paper component
    maxHeight: "220px", // Added: Limit the height of the Paper component
    "& img": {
      marginBottom: "0.5rem",
      width: "100%",
      borderRadius: "5px",
      objectFit: "cover",
    },
    "& .title": {
      fontSize: "1.3rem",
    },
  },
}));

const Recommendation = ({ tags }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await axiosClient.get(`/api/videos/tags?tags=${tags}`);
      setVideos(res.data);
    };
    fetchVideos();
  }, [tags]);

  return (
    <div className="recommended-videos-sidebar">
      <div className="recommended-videos">
      {videos.map((video) => (
        <RecommendedCards key={video._id} video={video} />
      ))}
    </div>
  </div>
  );
};

export default Recommendation;
