import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDgKmo4rh_bov4kVWRkEi9KLcx7JQ8mv98",
  authDomain: "test-ea4f5.firebaseapp.com",
  projectId: "test-ea4f5",
  storageBucket: "test-ea4f5.appspot.com",
  messagingSenderId: "609467658330",
  appId: "1:609467658330:web:d74d15c75fe326f20fe863",
  measurementId: "G-M85YHRZ8XF"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;