import { Box, Button, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
// import styled from "styled-components";

export const StyledChannelBanner = styled(Box)(({ theme }) => ({
  margin: "-40px -24px 0",
  marginTop: "10px",
  height: 300,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  maxWidth: "98%",
  backgroundPosition: "center center",
  borderRadius: "20px",
  backgroundColor: theme.palette.action.disabled,

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  "&.MuiAvatar-root": {
    height: 100,
    width: 100,
    border: "6px solid #ccc",
    backgroundColor: "#fff",
    alignSelf: "flex-end",
    margin: "-70px auto 20px",
    [theme.breakpoints.up("md")]: {
      margin: "-70px 16px 0 0",
    },
  },
}));

export const StyledChannelIntroBox = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  textAlign: "center",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
}));

export const StyledTabButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    textTransform: "none",
    borderRadius: 50,
    marginRight: 10,
  },
}));

export default StyledChannelBanner;
