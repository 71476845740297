import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Search, Clock, X, ArrowLeft } from 'lucide-react';
import { BASE_URL } from "../config";
import axiosClient from "../utils/client";
import "../css/searchBar.css";

// const BASE_URL = "http://localhost:3000/";

const SearchBar = ({ query, handleSearchQuery, handleMobileSearchClose }) => {
  const navigate = useNavigate();
  const [isDropVisible, setIsDropVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 656);

  const timerRef = useRef();
  const searchLimit = 1;


  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 656;
      setIsMobile(mobile);
      if (!mobile) {
        setIsExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (query.length > searchLimit) {
      getSearchData(query);
    } else {
      setIsDropVisible(false);
    }
  }, [query]);

  const clearSearch = () => {
    handleSearchQuery("");
    setIsDropVisible(false);
    setSearchSuggestions([]);
  };

  const handleSearchSubmit = () => {
    handleMobileSearchClose();
    setIsDropVisible(false);
    navigate(`/search?q=${query}`);
  };

  const updateSearch = (suggestion) => {
    handleSearchQuery(suggestion);
    setIsDropVisible(false);
    handleMobileSearchClose();
    navigate(`/search?q=${suggestion}`);
  };

  const handleSearch = (event) => {
    const inputValue = event.target.value;
    handleSearchQuery(inputValue);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (inputValue.length > searchLimit) {
      timerRef.current = window.setTimeout(() => {
        getSearchData(inputValue);
      }, 300);
      setIsDropVisible(true);
    } else {
      setSearchSuggestions([]);
      setIsDropVisible(false);
    }
  };

  const getSearchData = async (query) => {
    setIsLoading(true);

    try {
      const response = await axiosClient.get(
        //"api/videos/autocomplete",
        `${BASE_URL}api/videos/autocomplete`,
        { params: { query } }
      );

      setSearchSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching search suggestions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpand = () => setIsExpanded(true);
  const handleCollapse = () => {
    setIsExpanded(false);
    clearSearch();
  };

  return (
    <div className="search-container">
      {isMobile && !isExpanded && (
        <button onClick={handleExpand} className="navbar-search-mobile-button">
          <Search size={24} />
        </button>
      )}
      <div className={`navbar-search ${isExpanded ? 'expanded' : ''}`}>
        {isExpanded && (
          <button onClick={handleCollapse} className="navbar-search-back">
            <ArrowLeft size={24} />
          </button>
        )}
        <input
          type="text"
          placeholder="Search…"
          value={query}
          onChange={handleSearch}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`navbar-search-input ${isFocused ? 'focused' : ''}`}
        />
        {query && (
          <button onClick={clearSearch} className="navbar-search-clear">
            <X />
          </button>
        )}
        <button onClick={handleSearchSubmit} disabled={isLoading} className="navbar-search-button">
          {isLoading ? <CircularProgress size={20} /> : <Search />}
        </button>
      </div>
      {isDropVisible && (
        <ul className="search-suggestions">
          {searchSuggestions.map((suggestion, index) => (
            <li key={index} onClick={() => updateSearch(suggestion.title)}>
              <Search />
              {suggestion.title}
            </li>
          ))}
          {searchSuggestions.length === 0 && (
            <li className="no-results">No results found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
