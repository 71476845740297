import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemPreffered: false,
  darkMode: false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setUserPrefferedDarkTheme: (state) => ({
      systemPreffered: false,
      darkMode: true,
    }),
    setUserPrefferedLightTheme: (state) => ({
      systemPreffered: false,
      darkMode: false,
    }),
    setSystemPrefferedDarkTheme: (state) => ({
      systemPreffered: true,
      darkMode: true,
    }),
    setSystemPrefferedLightTheme: (state) => ({
      systemPreffered: true,
      darkMode: false,
    }),
  },
});

export const {
  setUserPrefferedDarkTheme,
  setUserPrefferedLightTheme,
  setSystemPrefferedDarkTheme,
  setSystemPrefferedLightTheme,
} = themeSlice.actions;

export default themeSlice.reducer;
