import React from 'react'
import Guidlines from '../components/Legal/Guidlines'
import "../css/guidlines.css";

const CommunityGuidlines = () => {
  return (
    <div className="mainContent">
        <Guidlines/>
    </div>
  )
}

export default CommunityGuidlines