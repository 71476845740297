import { nanoid } from "@reduxjs/toolkit";
import {
  Unstable_Grid2 as Grid,
} from "@mui/material";

import Card from "../../components/Card";
import CardSkeleton from "../../components/CardSkeleton";
import { generateKey } from '../../utils/helpers';


const ChannelCards = ({isLoadingVideos, videos, toShowValue}) => {

  return (
    <Grid container spacing={3} sx={{ pt: 3 }}>
      {isLoadingVideos && generateKey('CardSkeleton', 4).map((key) => (
        <Grid xs={12} key={key} sm={6} md={4} lg={3}>
          <CardSkeleton />
        </Grid>
      ))}
      {videos.map((video) => (
        <Grid key={nanoid()} xs={12} sm={6} md={4} lg={3}>
          <Card video={video} toShow={toShowValue} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ChannelCards;
