import React from 'react'
import "../../css/guidlines.css";


const Guidlines = () =>  {
  return (
    <div className="content">
      <h1 className="heading">Community Guidelines</h1>
      <p className="date">
        Date Modified: <time dateTime="2024-08-26">[August 26, 2024]</time>
      </p>
      <h2 className="subHeading">Introduction to Safety on Yivster</h2>
      <p className="description">
        Welcome to Yivster! Our mission is to create a welcoming and inclusive
        community where everyone can express themselves creatively and connect
        with others To maintain a safe environment, we ask that all users
        follow these community guidelines Violations may result in content
        removal, suspension, or termination of your account
      </p>

      <h2 className="subHeading">1. Hate Speech and Harassment</h2>
      <p className="description">
        Yivster does not allow content that promotes hatred or violence
        against individuals or groups based on race, ethnicity, religion,
        gender, sexual orientation, disability, or any other characteristic
        associated with systemic discrimination or marginalization We do not
        tolerate any form of harassment, bullying, or abusive behavior This
        includes but is not limited to personal attacks, unwanted sexual
        advances, and persistent negative comments
      </p>

      <h2 className="subHeading">2. Violence and Threats</h2>
      <p className="description">
        Content that glorifies violence, incites violent actions, or contains
        threats of harm against others is not allowed This includes threats
        related to physical harm, property damage, or threats of self-harm or
        suicide While fictional violence may be acceptable in certain
        contexts, graphic or real-life depictions of violence are not allowed
        and may be subject to removal
      </p>

      <h2 className="subHeading">3. Protection of Minors</h2>
      <p className="description">
        Yivster is committed to providing a safe environment for all users,
        especially minors We enforce strict policies to protect children from
        exploitation and harm
      </p>
      <ul className="list">
        <li className="listItem">
          <span className="highlight">- Age Requirement:</span> Users must
          be at least 13 years old or meet the minimum age required to provide
          digital consent in their specific State, Territory, or Region
        </li>
        <li className="listItem">
          <span className="highlight">- Parental Involvement:</span> Minors
          using Yivster must do so with the active involvement of a parent or
          legal guardian The parent or guardian must consent to Yivster’s
          Terms of Service and Community Guidelines
        </li>
        <li className="listItem">
          <span className="highlight">
            - Content Restrictions Involving Minors:
          </span>{" "}
          Adult creators are prohibited from streaming or uploading content
          featuring users under 18 unless the minor is in the immediate
          presence of a parent or legal guardian Users must respect the
          privacy and safety of minors at all times
        </li>
        <li className="listItem">
          <span className="highlight">
            - Zero-Tolerance Policy on Child Exploitation:
          </span>{" "}
          Yivster strictly prohibits the creation, distribution, or promotion
          of Child Sexual Abuse Material (CSAM) Any violation of this policy
          will result in immediate action, including reporting to law
          enforcement
        </li>
      </ul>

      <h2 className="subHeading">4. Nudity and Sexual Content</h2>
      <p className="description">
        Explicit sexual content and nudity are not permitted Educational,
        documentary, or artistic content involving nudity may be allowed in
        certain contexts but must be appropriately flagged and must not be
        sexually explicit Content that sexualizes minors or depicts minors in
        a sexually suggestive manner is strictly prohibited and will be
        reported to the authorities
      </p>

      <h2 className="subHeading">5. Sensitive Information and Doxxing</h2>
      <p className="description">
        Sharing or threatening to share another person’s private information
        (such as addresses, phone numbers, or financial information) without
        their consent is not allowed This includes encouraging others to do
        so Be mindful of the content you share and avoid posting personal or
        sensitive information that could be used to harm you or others
      </p>

      <h2 className="subHeading">
        6. Copyright and Intellectual Property
      </h2>
      <p className="description">
        Upload content that you own or have explicit permission to use
        Unauthorized use of copyrighted material, including music, videos,
        images, or software, is not allowed and may lead to content removal
        Yivster complies with the Digital Millennium Copyright Act (DMCA) and
        will respond to valid takedown requests Repeated copyright violations
        may lead to account termination
      </p>

      <h2 className="subHeading">7. Misinformation and Harmful Content</h2>
      <p className="description">
        Content that spreads false or misleading information, particularly
        related to public health, elections, or safety, is not permitted We
        encourage the dissemination of accurate and reliable information
        Content that promotes scams, fraudulent schemes, or dangerous
        challenges is strictly prohibited
      </p>

      <h2 className="subHeading">
        8. Spam, Scams, and Deceptive Practices
      </h2>
      <p className="description">
        Avoid posting repetitive, irrelevant, or unsolicited content
        Spamming, whether in comments, messages, or content uploads, is not
        allowed Misleading metadata (titles, thumbnails, descriptions)
        intended to deceive users or manipulate engagement is prohibited All
        interactions on Yivster should be genuine and honest
      </p>

      <h2 className="subHeading">9. Illegal Activities</h2>
      <p className="description">
        Content that promotes or enables illegal activities, such as drug
        trafficking, hacking, or the sale of illegal goods, is not allowed If
        you encounter content that violates the law, report it immediately
        Yivster cooperates with law enforcement to ensure the safety of our
        community
      </p>

      <h2 className="subHeading">
        10. Community Moderation and Reporting
      </h2>
      <p className="description">
        We rely on the community to help us identify content that violates
        these guidelines Please use the reporting tools to notify us of
        inappropriate content or behavior When reporting content, provide
        detailed information about the violation and include any relevant
        context False or malicious reports may result in account suspension
        If your content is removed or your account is suspended, you have the
        right to appeal the decision Our moderation team will review your
        appeal and make a fair and timely decision
      </p>

      <h2 className="subHeading">11. Enforcement and Consequences</h2>
      <p className="description">
        Violations of these guidelines may result in a strike against your
        account Accumulating multiple strikes can lead to more severe
        penalties, including temporary suspension or permanent ban Content
        that violates our guidelines will be removed In cases of severe or
        repeated violations, we may terminate your account to protect the
        community
      </p>

      <h2 className="subHeading">
        Thank you for being a part of Yivster!
      </h2>
      <p className="description">
        Together, we can build a community that is safe, respectful, and
        enjoyable for everyone If you have any questions or need assistance,
        please contact our support team
      </p>
    </div>
  )
}

export default Guidlines