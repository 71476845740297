import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Avatar,
  Grid,
  TextField,
  Link,
  Typography,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  signupStart,
  signupFailure,
} from "../redux/userSlice";
import Error from "../components/Error";
import axiosClient from "../utils/client";
import { useTheme } from "styled-components";
import SignUpModal from '../components/SignUpModal';


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [signUpError, setsignUpError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isDiabled, setIsDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(null);
  const [toShowCheckBox, setToShowCheckBox] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  
  useEffect(() => {
    // console.log('userType', userType)
    // console.log('isDiabled', isDiabled)
    // console.log('isChecked', isChecked)
    if (userType === "user") {
      setToShowCheckBox(false);
      setIsDisabled(false);
    } else if (userType === "vendor") {
      setToShowCheckBox(true);
    }
    if (userType === "vendor" && isChecked) {
      // console.log('asdasdasd')
      setIsDisabled(false);
      // console.log('isDiabled*******', isDiabled)
    }
  }, [userType, isDiabled, isChecked]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    dispatch(signupStart());

    try {
      const res = await axiosClient.post("/api/auth/signup", {
        name,
        username,
        email,
        password,
        userType,
      });

      // console.log('***********', res.data);
      setSuccess(res.data);

      if (res.data) {
        // console.log('userType', userType)

        if (userType === "vendor") {
          const createAccount = await axiosClient.post("/api/auth/create/account", {
            email,
          });
          console.log("createAccount.data", createAccount.data);
          if (createAccount.data) {
            window.open(createAccount.data.url, "_blank");
          }
        }
      }
    } catch (err) {
      setsignUpError(err.response.data.message);
      dispatch(signupFailure());
      setTimeout(() => {
        setsignUpError(false);
      }, 10000);
      // console.log(err);
      // console.log(err.response.data);
      // console.log(err);
      // console.log(err.response.data);
    }
  };

  return (
    <div className="signup-main-content">
      <SignUpModal />
    </div>
  );
};

export default SignUp;
