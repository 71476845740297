// MembershipModal.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
// import axiosClient from "../../utils/client";
import { BASE_URL } from "../../config";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MembershipModal = ({ open, handleClose, channel, page, pageId }) => {
  const stripe = useStripe();

  const handleSubscribe = async () => {
    try {
      const response = await axios.put(`${BASE_URL}/api/users/join/${channel?._id}`, { amount: 6 * 100, page, pageId});
      // const response = await axiosClient.put(`/api/users/like/${currentVideo._id}`);

      console.log('response', response.data)
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
      // const sessionId  = response?.data?.id
      // const { error } = await stripe.redirectToCheckout({ sessionId });
      // if (error) {
      //   console.error(error);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <ModalHeader>
          <Typography variant="h6">Membership</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ backgroundColor: 'grey.400', mx: -3, p: 3, mb: 2 }}>
          <Avatar src={channel?.img} alt={channel?.username} sx={{ width: 56, height: 56, mb: 2 }} />
          <Typography variant="h6">{channel?.username}</Typography>
          <Typography variant="body1" mb={2}>Join this channel</Typography>
          <Typography variant="body1" mb={2}>Get access to membership perks</Typography>
          <Typography variant="h6" mb={2}>USD 5.99/month</Typography>
          <Button variant="contained" onClick={handleSubscribe}>Join</Button>
        </Box>
        <Typography variant="body2" mt={2}>Recurring payment. Cancel anytime. Creator may update perks.</Typography>
        <Box mt={2} textAlign="left" width="100%">
          <Typography variant="body2" mb={1}>Loyalty badges next to your name in comments and live chat</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {
              channel?.channelInfo?.channelBadges && channel?.channelInfo?.channelBadges.map((imgSrc) => (
                <Box component="img" sx={{ width: 30 }} key={imgSrc} src={imgSrc} alt="Channel Badge" />
              ))
            }
          </Box>
          <Typography variant="body2" mb={1}>Custom emoji to use in comments and live chat</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {
              channel?.channelInfo?.channelEmojis && channel?.channelInfo?.channelEmojis.map((imgSrc) => (
                <Box component="img" sx={{ width: 30 }} key={imgSrc} src={imgSrc} alt="Channel Emojis" />
              ))
            }
          </Box>
          <Typography variant="body2">Support the channel beyond watching the videos!</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipModal;