import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer  from "./userSlice";
import themeReducer  from "./themeSlice";
import videoReducer from "./videoSlice"
import commentReducer from "./commentSlice";
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,} from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const rootReducer = combineReducers ({user: userReducer, video: videoReducer, comment: commentReducer, theme: themeReducer})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck:{
            ignoreActions:  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
        },
    }),
});

export const persistor = persistStore(store)


/* storage
 ---user
 ----- currentUser, loading, error */
