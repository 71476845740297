import { Box } from "@mui/material";

const GetRandomBadge = ({badges}) => {
  if (!badges || badges.length === 0) {
    return;
  }

  const randomIndex = Math.floor(Math.random() * badges.length);
  const badge = badges[randomIndex];

  return (
    <Box
      component="img"
      src={badge}
      sx={{
        width: 20,
        display: 'inline-block',
        verticalAlign: 'middle',
        mx: 0.5
      }}
      alt="badge"
    />
  );
};

export default GetRandomBadge;
