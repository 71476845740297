import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import axiosClient from '../utils/client';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 400px;
  background-color: var(--secondary-light-color);
  color: var(--text-main);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: var(--text-light-main);
  font-size: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  color: var(--text-main);
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Input = styled.input`
  border: 1px solid #a9a9a9;
  color: var(--text-main);
  border-radius: 4px;
  padding: 12px;
  background-color: transparent;
  width: 100%; /* Ensures it fills the container */
  font-size: 16px;
  margin-bottom: 20px;
  box-sizing: border-box; /* Ensures padding is included in width */

  &:focus {
    outline: none;
    border-color: #3573d9;
  }
`;

const Button = styled.button`
  border-radius: 4px;
  border: none;
  padding: 12px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ copied }) => (copied ? "#a7282c" : "#3f8efc")};
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ copied }) => (copied ? "#8a2022" : "#3573d9")};
  }
`;

const StripeModal = ({ setOpenModal, videoId, channelName, defaultAmount = 5 }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [inputAmount, setInputAmount] = useState(defaultAmount);
  const [isValid, setIsValid] = useState(false);

  const payNow = async () => {
    try {
      const response = await axiosClient.post("/api/users/send-tip", {
        amount: inputAmount * 100,
        videoId,
      });
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
      setOpenModal(false);
      setIsValid(true);
    } catch (err) {
      setIsCopied(true);
      setTimeout(() => setOpenModal(false), 2000);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Close onClick={() => setOpenModal(false)}>×</Close>
        <Title>Send {channelName} A Tip</Title>
        <Input
          type="text"
          placeholder="Enter Amount"
          value={inputAmount}
          onInput={(e) => setInputAmount(e.target.value)}
        />
        <Button onClick={payNow} copied={isCopied}>
          {isCopied ? `${channelName} cannot receive tips right now` : "Tip"}
        </Button>
      </Wrapper>
      {isValid && <Alert variant="success">Thank you for the payment!</Alert>}
    </Container>
  );
};

export default StripeModal;
