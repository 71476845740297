import { Box, Link, Typography, Button, colors } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';
import { nanoid } from "@reduxjs/toolkit";
import axiosClient from "../../utils/client";
import { subscription } from "../../redux/userSlice";

import {
  VideoContainer,
  StyledDescription,
  StyledAvatarFrame,
  StyledChannelAvatar,
} from "./styles";

const SearchChannelCard = ({ channel }) => {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSub = async () => {
    currentUser.subscribedUsers.includes(channel?._id)
      ? await axiosClient.put(`/api/users/unsub/${channel?._id}`)
      : await axiosClient.put(`/api/users/sub/${channel?._id}`);
    dispatch(subscription(channel?._id));
  };

  return (
    <VideoContainer className="video-container">
      <Link
        to={`/channel/${channel?._id}`}
        underline="none"
        component={RouterLink}
      >
        <StyledAvatarFrame>
          <StyledChannelAvatar
            sx={{ bgcolor: colors.deepOrange[500] }}
            alt="Styled Channel Avatar"
            src={channel?.img}
          >
            <Typography sx={{ fontSize: '3rem' }}>S</Typography>
          </StyledChannelAvatar>
        </StyledAvatarFrame>
      </Link>
      <Box sx={{ ml: { sm: 0, md: 3 }, py: { sm: 2, md: 0 } }}>
        <Typography variant="subtitle1" sx={{ fontSize: '1.125rem' }}>
          <Link
            to={`/channel/${channel?._id}`}
            underline="hover"
            textTransform="capitalize"
            component={RouterLink}
          >{channel?.channelInfo?.name}</Link>
        </Typography>
        <Typography variant="body2" sx={{ color: "var(--main-text-color)" }}>
          {channel?.subscribers} Subscribers
        </Typography>
        <Typography variant="body2" sx={{ color: "var(--main-text-color)" }}>
          {channel?.channelInfo?.info.location} • {channel?.channelInfo?.info.inquiries}
        </Typography>
        {
          currentUser && (
            <Button
              variant="contained"
              onClick={handleSub}
              sx={{ borderRadius: 50, mt: 1 }}
            >
              {currentUser?.subscribedUsers?.includes(channel?._id)
                ? "SUBSCRIBED"
                : "SUBSCRIBE"}
            </Button>
          )
        }
        <Box sx={{ display: 'flex', pt: 2, gap: 1 }}>
          {
            Object.keys(channel?.channelInfo?.socials).map((key) => (
              <SocialIcon
                key={nanoid()}
                url={channel?.channelInfo?.socials[key]}
                aria-label={key}
                label={key}
                style={{
                  height: '25px',
                  width: '25px'
                }}
              />
            ))
          }
        </Box>
        <StyledDescription sx={{ pt: 2, fontSize: '0.9rem', color: "var(--main-text-color)" }}>
          {channel?.channelInfo?.description}
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi quo quaerat voluptatem quibusdam eligendi, deserunt maiores, modi, itaque repudiandae expedita necessitatibus ducimus saepe suscipit nesciunt optio alias aliquid officia. Nihil!
        </StyledDescription>
      </Box>
    </VideoContainer>
  );
};

export default SearchChannelCard;
