// Path: client/src/redux/commentSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentComment: null,
  loading: false,
  error: false,
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action) => {
      state.loading = false;
      //sending the current video data to the payload
      state.currentComment = action.payload;
    },
    fetchFailure: (state) => {
      state.loading = false;
      state.error = true;
  },
     createComment: (state, action) => {
      state.currentComment = action.payload;
    }, 
   /*  if(!state.comments.includes(action.payload)){
        state.comments.push(action.payload);
    } */


  },
});

export const { fetchStart, fetchSuccess, fetchFailure, createComment } = commentSlice.actions;

export default commentSlice.reducer;





