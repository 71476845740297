import React from "react";
import { Alert } from "react-bootstrap";

const ErrorMessage = ({ variant = "info", children }) => {
  return (
    <Alert
      variant={variant}
      style={{
        fontSize: 20,
        backgroundColor: "#3F8EFC",
        color: "white",
        border: "none",
        borderRadius: "20px",
        padding: "10px 15px",
        textAlign: "center",
      }}
    >
      <strong>{children}</strong>
    </Alert>
  );
};

export default ErrorMessage;

//work on error handling and fix the view count
