import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  currentVideo: null,
  loading: false,
  error: false,
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    fetchVideo: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action) => {
      state.loading = false;
      //sending the current video data to the payload
      state.currentVideo = action.payload;
    },
    fetchFailure: (state) => {
      state.loading = false;
      state.error = true;
  },
  like:(state,action)=> {
    //if the user didnt like the video before let them like it
    if(!state.currentVideo.likes.includes(action.payload)){
      state.currentVideo.likes.push(action.payload)
      //  if the user dislikes the video already find it and remove it 
       state.currentVideo.dislikes.splice(
         state.currentVideo.dislikes.findIndex(
          (userId) => userId === action.payload
      ), 1
      )
    }
  },
 dislike: (state, action) => {
  if (!state.currentVideo.dislikes.includes(action.payload)) {
    state.currentVideo.dislikes.push(action.payload);
    state.currentVideo.likes.splice(
      state.currentVideo.likes.findIndex(
        (userId) => userId === action.payload
      ),
      1
    );
   }
  },
 },
});
export const { fetchVideo, fetchSuccess, fetchFailure, like, dislike} = videoSlice.actions;

export default videoSlice.reducer;