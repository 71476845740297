import { Paper, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    max-width: 1700px;
    margin: 0 auto;
  }
`;
export const Content = styled('div')`
  flex: 5;
`;

export const SideBar = styled('div')`
  @media screen and (min-width: 1200px) {
    max-width: 380px;
  }
`;

export const SideBarInner = styled('div')`
  position: sticky;
  top: 85px;
`;

export const Title = styled('h1')`
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0;
  color: "#121212";
  @media screen and (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Details = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: "#121212" @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Info = styled('span')`
  color: "#121212"
  margin-right: 20px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;
export const Buttons = styled('div')`
  display: flex;
  gap: 8px 20px;
  color: ${({ theme }) => theme.text};
  @media screen and (max-width: 767px) {
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

export const Channel = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.up('sm')]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));

export const ChannelInfo = styled('div')`
  display: flex;
  gap: 20px;
  color: "#121212" @media screen and (max-width: 767px) {
    align-items: center;
  }
`;
export const Image = styled('img')`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`;
export const ChannelDetail = styled('div')`
  display: flex;
  flex-direction: column;
  color: "#121212";
`;
export const ChannelName = styled('span')`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
export const ChannelCounter = styled('span')`
  margin-top: 5px;
  margin-bottom: 20px;
  color: "#121212"
  font-size: 12px;
`;

export const VideoWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '56.25%',
  width: "100%",
  borderRadius: "5px",
  maxWidth: "1500px",
  margin: "0 auto",
  overflow: "hidden",

  [theme.breakpoints.down('md')]: {
    width: "calc(100% + 48px)",
    marginLeft: "-24px",
    borderRadius: "0",
  },
  [theme.breakpoints.down('sm')]: {
    width: "calc(100% + 56px)",
    marginLeft: "-32px"
  }
}));

export const VideoFrame = styled("video")(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  width: "100%",
  height: "100%",
  background: "#000",
  maxHeight: "calc(100vh - 110px)"
}));

export const CommentsBackdrop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: "fixed",
    background: "rgba(0,0,0,.5)",
    display: "none",
    alignItems: "flex-end",
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 9999,
    "&.active": {
      display: "flex",
    },
  }
}));

export const CommentsContainer = styled(Box)(({ theme }) => ({

  [theme.breakpoints.down('md')]: {
    padding: "10px 20px",
    background: "white",
    flexGrow: 1,
    maxHeight: "70vh",
    overflow: "auto"
  }
}));

export default Container;
