import { styled } from "@mui/system";
import { Typography, Avatar, Box } from "@mui/material";

export const VideoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    flexDirection: "column", // Stack elements vertically on mobile
  },
}));

export const Thumbnail = styled(Box)(({ theme }) => ({
  width: "360px",
  height: "200px",
  "> div": {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "10px",
  },
  " video": {
    height: "100%",
  },
}));

export const StyledAvatarFrame = styled(Box)(({ theme }) => ({
  width: "360px",
}));

export const ChannelContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const ChannelAvatar = styled(Avatar)({
  width: "22px",
  height: "22px",
  marginRight: (theme) => theme.spacing(1),
});

export const StyledDescription = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
});

export const StyledChannelAvatar = styled(Avatar)(({ theme }) => ({
  "&.MuiAvatar-root": {
    height: "136px",
    width: "136px",
    border: "1px solid #ccc",
    alignSelf: "flex-end",
    margin: "0 auto",
  },
}));
