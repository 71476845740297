import { createSlice } from "@reduxjs/toolkit";

//in the beginining no user is logged in
const initialState = {
  currentUser: null,
  loading: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //a user is trying to login
    loginStart: (state) => {
      state.loading = true;
    },
    //a user is able to login succesfully
    loginSuccess: (state, action) => {
      state.loading = false;
      //updating our user
      state.currentUser = action.payload.user;
    },
    //a user isn't able to login succesfully
    loginFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    signupStart: (state) => {
      state.loading = true;
    },
    //a user is able to login succesfully
    signupSuccess: (state, action) => {
      state.loading = false;
      //updating our user
      state.currentUser = action.payload;
    },
    //a user isn't able to login succesfully
    signupFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    //a user isn't able to login succesfully
    logout: (state) => {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userPassword");
      localStorage.removeItem("ServiceToken");
      return {
        ...state,
        currentUser: null,
        loading: false,
        error: false,
      }
    },
    getUser: (state, action) => {
      state.currentUser = action.payload;
    },
    updateUser: (state, action) => {
      state.currentUser = action.payload;
    },
    subscription: (state, action) => {
      // if the current user subscribed users includes channel id
      if (state.currentUser.subscribedUsers.includes(action.payload)) {
        //delete the channel id
        state.currentUser.subscribedUsers.splice(
          //find the index and delete it
          state.currentUser.subscribedUsers.findIndex(
            (channelId) => channelId === action.payload
          ),
          1
        );
      } else {
        //if they didnt subscribe before push their channel id into their subscribed users list
        state.currentUser.subscribedUsers.push(action.payload);
      }
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  subscription,
  signupStart,
  signupSuccess,
  signupFailure,
  updateUser,
  getUser,
} = userSlice.actions;

export default userSlice.reducer;
